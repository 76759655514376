import React, { useContext } from 'react';
import { SliceZone } from '@prismicio/react';
import { Grid } from '@mezzoforte/forge';
import { PrismicSettingsContext } from '../prismic/prismic-settings-context';
// @ts-ignore
import { components as sliceComponents } from './slices';
import NavContainer from './NavContainer';

export const MainNavigation = () => {
  const prismicSettings = useContext(PrismicSettingsContext);

  const slices = prismicSettings?.data.slices2;

  return (
    <NavContainer>
      <Grid
        autoFlow="column"
        as="ul"
        gap={{ base: 2, lg: 4 }}
        m={0}
        px={{ base: 2, lg: 0 }}
        py={{ base: '10px', lg: 0 }}
      >
        <SliceZone
          slices={slices}
          components={sliceComponents}
        />
      </Grid>
    </NavContainer>
  );
};
