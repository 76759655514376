import { List, Map } from 'immutable';
import companyService from '../../src/company/company-service';
import { SellerType } from '../../src/net-auction/seller-type';

const FETCH_COMPANIES = 'huutokaupat/form/company/FETCH_COMPANIES';
const FETCH_COMPANIES_DONE = 'huutokaupat/form/company/FETCH_COMPANIES_DONE';
const FETCH_COMPANIES_FAILED = 'huutokaupat/form/company/FETCH_COMPANIES_FAILED';

const initialState = new Map({
  loading: false,
  failed: false,
  companies: new List(),
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_COMPANIES:
      return state.set('loading', true).set('failed', false).set('companies', new List());

    case FETCH_COMPANIES_DONE:
      return state.set('loading', false).set('companies', new List(action.payload));

    case FETCH_COMPANIES_FAILED:
      return state.set('loading', false).set('failed', true);

    default:
      return state;
  }
};

export function fetchCompaniesForSelect(sellerType = SellerType.Companies) {
  return dispatch => {
    dispatch({ type: FETCH_COMPANIES });

    companyService
      .fetchCompanies({ simple: true, sellerType })
      .then(companies => dispatch({ type: FETCH_COMPANIES_DONE, payload: companies }))
      .catch(error => dispatch({ type: FETCH_COMPANIES_FAILED, payload: error }));
  };
}
