// @flow

import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Modal, Form, FormGroup, Button } from 'react-bootstrap';
import jwt from '../../../../src/security/jwt';
import SelectField from '../../../form/SelectField';
import { usePrivateNetAuctionGroups } from '../../../../hooks/net-auction/private-net-auction-group';
import type { PrivateNetAuctionGroup } from '../../../../src/net-auction/private-net-auction-group-service';
import InputField from '../../../form/InputField';
import CheckboxField from '../../../form/CheckboxField';
import { useCompanySelection } from '../../../../hooks/company-selection/use-company-selection';

type Props = {
  company?: any,
  submitting: boolean,
  onClose: Function,
  handleSubmit: Function,
};

function AdvancedSearchModal({ company, submitting, onClose, handleSubmit }: Props): React$Node {
  const { selectedCompany } = useCompanySelection();

  const shouldDisplayCustomReferenceField =
    (jwt.getUser() && jwt.getUser().isAdmin) || selectedCompany?.isCustomReferenceNumberEnabled;

  const { groups, loading, error } = usePrivateNetAuctionGroups({
    company: selectedCompany?.id ?? company?.id,
  });

  return (
    <Modal
      bsSize="sm"
      show
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Tarkennettu haku</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <FormGroup>
            <Field
              name="keyword"
              label="Kohteen nimi tai kohdenumero"
              component={InputField}
            />
          </FormGroup>
          {/* TODO: Use company from auth duck instead. */}
          {shouldDisplayCustomReferenceField && (
            <FormGroup>
              <Field
                name="viite"
                label="Ilmoittajan oma viite"
                component={InputField}
              />
            </FormGroup>
          )}

          <FormGroup>
            <Field
              name="sahkoposti"
              label="Ostajan sähköposti"
              component={InputField}
            />
          </FormGroup>
          <FormGroup>
            <Field
              name="puhelin"
              label="Ostajan koko puhelinnumero"
              component={InputField}
            />
          </FormGroup>
          <FormGroup>
            <Field
              name="ostaja"
              label="Ostajan nimi"
              component={InputField}
            />
          </FormGroup>
          <FormGroup>
            <Field
              name="ytunnus"
              label="Ostajan yrityksen Y-tunnus"
              component={InputField}
            />
          </FormGroup>
          <FormGroup>
            <Field
              name="yritys"
              label="Ostajan yrityksen nimi"
              component={InputField}
            />
          </FormGroup>
          <FormGroup>
            <Field
              name="reknro"
              label="Ajoneuvon rekisteritunnus"
              component={InputField}
            />
          </FormGroup>
          <FormGroup>
            <Field
              name="vin"
              label="Ajoneuvon valmistenumero"
              component={InputField}
            />
          </FormGroup>
          <FormGroup>
            <Field
              name="toim"
              label="Toimeksiantajan nimi"
              component={InputField}
            />
          </FormGroup>
          <FormGroup>
            <Field
              name="toimytunnus"
              label="Toimeksiantajan y-tunnus"
              component={InputField}
            />
          </FormGroup>
          <FormGroup>
            <Field
              name="tapahtuma"
              label="Suljettu huutokauppatapahtuma"
              disabled={loading || error}
              loading={loading}
              component={SelectField}
              options={groups.map(({ id, title }: PrivateNetAuctionGroup) => ({
                name: title,
                value: id,
              }))}
            />
          </FormGroup>
          <FormGroup>
            <Field
              name="includeOld"
              label="Hae myös vanhoista kohteista"
              component={CheckboxField}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose}>Takaisin</Button>
          <Button
            bsStyle="primary"
            type="submit"
            disabled={submitting}
          >
            Hae
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default reduxForm({
  form: 'advancedSearchModal',
})(AdvancedSearchModal);
