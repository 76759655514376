import { useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSession } from '@app/src/header/hooks/useSession';
import {
  clearSelectedCompanyId,
  saveSelectedCompanyId,
  getSelectedCompanyId,
} from '@app/src/util/company-selection-storage';
import { useToast } from '@mezzoforte/forge';

export const useCompanySelection = () => {
  const { currentUser } = useSession();
  const { playToast } = useToast();
  const selectedCompanyIdQuery = useQuery({ queryKey: ['selected-company-id'], queryFn: getSelectedCompanyId });

  const selectedCompany = useMemo(() => {
    if (!selectedCompanyIdQuery.data) {
      return undefined;
    }

    return currentUser.data?.companies?.find(company => company.id === selectedCompanyIdQuery.data);
  }, [selectedCompanyIdQuery.data, currentUser.data?.companies]);

  useEffect(() => {
    if (currentUser.isFetching) {
      return;
    }

    if (currentUser.data?.isAuthenticated === false) {
      // Don't clear selected company to keep it in case the same user will be next to log in
      // The company selection will stay even if a different user logs in and has access to the same company, I don't think that's great but really how common can it be?
      return;
    }

    const userCompanies = currentUser.data?.companies;
    if (!userCompanies || userCompanies.length === 0) {
      clearSelectedCompanyId();
      return;
    }

    const isInvalidCompanySelection = !userCompanies.find(company => company.id === selectedCompanyIdQuery.data);
    if (isInvalidCompanySelection || selectedCompanyIdQuery.data === null) {
      const consumerCompany = userCompanies.find(company => company.isConsumer);
      saveSelectedCompanyId(consumerCompany?.id ?? userCompanies[0].id);
      return;
    }
  }, [currentUser.isFetching, currentUser.data, selectedCompanyIdQuery.data]);

  function selectCompanyId(companyId: number) {
    saveSelectedCompanyId(companyId);
    void selectedCompanyIdQuery.refetch();
  }

  function switchCompanyIfNeeded(wantedCompanyId: number) {
    const userCompanies = currentUser.data?.companies;
    const foundCompany = userCompanies?.find(company => company.id === wantedCompanyId);

    if (!foundCompany || selectedCompany?.id === wantedCompanyId) {
      return;
    }

    selectCompanyId(wantedCompanyId);
    playToast(`Siirryttiin käyttämään ${foundCompany.name}`, undefined, {
      variant: 'success',
      toastId: 'company-changed',
    });
  }

  return {
    selectCompanyId,
    selectedCompany,
    switchCompanyIfNeeded,
  };
};
