import fileSaver from 'file-saver';
import createClient from '@app/src/api/client';
import { base64ToBlob } from '@app/src/util/file';

async function fetchBillOfSale(entryId: number, role: 'buyer' | 'seller') {
  return createClient()
    .get(`/net-auctions/${entryId}/file/bill-of-sale`, { params: { role } })
    .then(response => ({
      filename: response.data.filename,
      content: new Blob([base64ToBlob(response.data.content)], {
        type: 'application/pdf',
      }),
    }));
}

export async function downloadBillOfSale(entryId: number, role: 'buyer' | 'seller') {
  return fetchBillOfSale(entryId, role).then(file => fileSaver.saveAs(file.content, file.filename));
}
