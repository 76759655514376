import React, { useRef } from 'react';
import { AbsoluteCenter } from '@chakra-ui/react';
import { Flex, Modal, ModalBody, Button, Heading, Box, Divider, Link } from '@mezzoforte/forge';
import { X } from '@mezzoforte/forge-icons';
import { identificationService } from '@app/src/identification/identification-service';
import { LoginForm, LoginFormValues } from '@components/login/LoginForm';
import { LoginError } from '@components/login/LoginError';
import { MfaForm } from '@components/login/MfaForm';

export type LoginModalState = 'login' | 'mfa';
export type LoginModalError =
  | 'failed'
  | 'ip-restricted'
  | 'banned'
  | 'forced-password-reset'
  | 'deletion-ongoing'
  | 'internal-error';

export interface LoginModalProps {
  readonly isOpen: boolean;
  readonly isSimplified?: boolean;
  readonly returnUrl?: string;
  readonly state: LoginModalState;
  readonly error?: LoginModalError;
  readonly onLoginSubmit: (values: LoginFormValues) => Promise<void>;
  readonly onMfaSubmit: (passcode: string) => Promise<void>;
  readonly onClose: () => void;
}

/**
 * Removed bootstrap version and matched with huutokaupat-frontend's modal
 * TODO: After we are using a more recent version of the design system:
 *   - Add missing features to huutokaupat-frontend's login modal
 *     - Expired passwords
 *     - ...?
 *   - Our LoginProvider could be changed to use react-query instead.
 */
export function LoginModal({ isOpen, returnUrl, state, error, onLoginSubmit, onMfaSubmit, onClose }: LoginModalProps) {
  const initialFocusRef = useRef(null);

  const isError = !!error;

  const renderHeader = () => (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      mb={3}
      px={4}
    >
      <Heading
        variant="h3"
        as="h2"
        margin={0}
      >
        Kirjaudu sisään
      </Heading>
      <Button
        icon={<X />}
        aria-label="Sulje"
        onClick={onClose}
        tabIndex={-1}
      />
    </Flex>
  );

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onClose}
      initialFocusRef={initialFocusRef}
      variant="custom"
      size={{ base: 'xs', sm: 'sm', lg: 'lg', xl: 'xl' }}
    >
      {renderHeader()}
      <ModalBody>
        {isError && <LoginError error={error} />}
        <Box>
          {state === 'login' && (
            <LoginForm
              onSubmit={onLoginSubmit}
              isError={isError}
              ref={initialFocusRef}
            />
          )}
          {state === 'mfa' && (
            <MfaForm
              onSubmit={onMfaSubmit}
              isError={isError}
            />
          )}
        </Box>
        <Box
          position="relative"
          padding="5"
          color="rgba(117, 117, 117,1)"
        >
          <Divider margin={0} />
          <AbsoluteCenter
            bg="white"
            px="4"
          >
            tai
          </AbsoluteCenter>
        </Box>
        {isError && (
          <Link
            href={identificationService.getIdentificationUrl('login', returnUrl ?? '/')}
            width="100%"
            mb={2}
          >
            <Button
              as="span"
              variant="default-hero"
              onClick={onClose}
              width="100%"
              mb={2}
            >
              Kirjaudu tunnistamalla
            </Button>
          </Link>
        )}

        <Link
          href="/tee-tunnus"
          width="100%"
        >
          <Button
            as="span"
            variant="default-hero"
            onClick={onClose}
            width="100%"
          >
            Ei tunnusta vielä? Tee ilmainen tunnus
          </Button>
        </Link>
      </ModalBody>
    </Modal>
  );
}
