import React from 'react';
import { Img } from '@chakra-ui/react';
import { Box, Flex, Image, Link } from '@mezzoforte/forge';
import { Container } from './Container';
import { MainNavigation } from './MainNavigation';
import { HeaderTextBanners } from './HeaderTextBanners';
import { HeaderSearchForm } from './HeaderSearchForm';
import Huutokaupat from '../../../../images/logos/huutokaupat-full-white.svg';
import HidingStickyContainer from './HidingStickyContainer';

export const NAV_HEIGHT = 74;

export function SiteHeader() {
  return (
    <>
      <HeaderTextBanners data-test="header-text-banners" />
      <HidingStickyContainer
        background="brandSecondary"
        as="header"
        zIndex={20}
        width="100%"
        color="textInvert"
        height={NAV_HEIGHT}
        topOffset={37}
      >
        <Container>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            py={{ base: 2, sm: 3 }}
            position="relative"
          >
            <Box
              as={Link}
              href="/"
              display="flex"
              height={{ base: 'auto', lg: '40px' }}
            >
              <Img
                as={Image}
                src={Huutokaupat}
                alt="Huutokaupat.com"
                height={{ base: '32px', sm: '40px' }}
                width={{ base: '133px', sm: '222px' }}
              />
            </Box>
            <Flex
              alignItems="center"
              style={{ gap: '1.5rem' }}
            >
              <MainNavigation />
              <HeaderSearchForm />
            </Flex>
          </Flex>
        </Container>
      </HidingStickyContainer>
    </>
  );
}
