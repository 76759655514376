/* eslint-disable @typescript-eslint/naming-convention -- twig global */
import React, { createContext } from 'react';
import type { SettingsDocument } from './prismicio';
import config from '../../../config';

export const PrismicSettingsContext = createContext<SettingsDocument>(config.prismicSettings);

export default function PrismicSettingsContextProvider(props: React.PropsWithChildren<Record<string, never>>) {
  return (
    <PrismicSettingsContext.Provider
      value={config.prismicSettings}
      {...props}
    />
  );
}
