import { useQuery } from '@tanstack/react-query';
import type { CategoriesWithCountsAPIResponse, CategoryAPIResponse } from '../types/ApiResponse';
import type { CategoriesDynamicData, CategoryDynamicData } from '../types/Category';
import createClient from '@app/src/api/client';

function categoryFromAPIResponse(responseItem: CategoryAPIResponse, order: number): CategoryDynamicData {
  const category: CategoryDynamicData = {
    order,
    id: responseItem.category.id,
    name: responseItem.category.htmlName,
    children: {},
    parentId: responseItem.category.parentCategoryId,
    entryCount: responseItem.count,
  };

  // Create children recursively
  (responseItem.children ?? []).forEach((c, i) => {
    category.children[c.category.id] = categoryFromAPIResponse(c, i);
  });

  return category;
}

export const fetchCategories = () => {
  return createClient('')
    .get<CategoriesWithCountsAPIResponse>('/api/category-list')
    .then(res => res.data)
    .then(response => ({
      ...response,
      categories: response.categories.map<CategoryDynamicData>(categoryFromAPIResponse),
    }));
};

export const useCategories = () => {
  return useQuery<CategoriesDynamicData>({
    queryKey: ['categories'],
    queryFn: fetchCategories,
    staleTime: 1000 * 60 * 10,
  });
};
