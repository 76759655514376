// eslint-disable-next-line import/no-cycle -- Dependency cycle
import createClient from '../api/client';
import storage from '../util/storage';

export interface TokenResponse {
  readonly success: boolean;
  readonly token: string;
}

export interface AuthUser {
  readonly id: number;
  readonly uuid: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly phoneNumber: string;
  readonly isBuyer: boolean;
  readonly isSeller: boolean;
  readonly isConsumerSeller: boolean;
  readonly isAdmin: boolean;
  readonly isAccounting: boolean;
  readonly isCustomerService: boolean;
  readonly isInfoMissing: boolean;
  readonly preventDataCollection: boolean;
  readonly isIdentificationMissing: boolean;
  readonly isEmailConfirmed: boolean;
}

export interface Token {
  readonly user: AuthUser;
}

const isToken = (token: unknown): token is Token => typeof token === 'object' && token !== null && 'user' in token;

/**
 * @deprecated Use useSession instead.
 */
const getToken = (): string | null => storage.get('sessiontoken');

/**
 * @deprecated Use useSession instead.
 */
const setToken = (token: string): void => storage.set('sessiontoken', token);

/**
 * @deprecated Use useSession instead.
 */
const clearToken = (): void => storage.remove('sessiontoken');

/**
 * @deprecated Use useSession instead.
 */
const decodeToken = (token: string): Token | null => {
  try {
    const decoded = atob(token);
    const parsed: unknown = JSON.parse(decoded);

    return isToken(parsed) ? parsed : null;
  } catch {
    return null;
  }
};

/**
 * @deprecated Use useSession instead.
 */
const requestTokenWithSession = async () =>
  createClient()
    .post<TokenResponse>('/token/from-session')
    .then(({ data }): void => {
      if (data.success) {
        setToken(data.token);
      }
    });

const getDecodedToken = (): Token | null => {
  const token = getToken();
  if (!token) {
    return null;
  }

  const decoded = decodeToken(token);
  if (!decoded) {
    clearToken();
  }

  return decoded;
};

/**
 * @deprecated Use useSession instead.
 */
const getUser = (): AuthUser | null => {
  const decoded = getDecodedToken();

  return decoded ? decoded.user : null;
};

export default {
  getToken,
  setToken,
  clearToken,
  decodeToken,
  requestTokenWithSession,
  getUser,
};
