import React, { useState } from 'react';
import { Box, Button, TextInput, useTokens } from '@mezzoforte/forge';

const { colors } = useTokens.huutokaupat;

export interface MfaFormProps {
  readonly onSubmit: (passcode: string) => Promise<void>;
  readonly isError: boolean;
}

export function MfaForm({ onSubmit, isError }: MfaFormProps) {
  // ToDo: Once LoginProvider login is refactored to use react-query we can get rid of this
  // internal pending state.
  const [isPending, setPending] = useState(false);

  const [passcode, setPasscode] = useState('');

  async function submitTwoFactor(event: React.FormEvent<HTMLFormElement>) {
    setPending(true);
    event.preventDefault();
    await onSubmit(passcode.replaceAll(/\s+/g, ''));
    setPending(false);
  }

  return (
    <Box
      as="form"
      bgColor={colors.backgroundInfo}
      p={3}
      onSubmit={submitTwoFactor}
      data-test="hk-two-factor-form"
    >
      <TextInput
        data-test="hk-passcode-field"
        name="passcode"
        label="Anna kaksivaiheisen tunnistautumisen vahvistuskoodi"
        autoComplete="one-time-code"
        autoFocus
        isRequired
        isDisabled={isPending}
        isInvalid={isError}
        errorInfo="Virheellinen vahvistuskoodi"
        value={passcode}
        onInput={e => setPasscode(e.currentTarget.value)}
      />
      <Button
        type="submit"
        variant="highlight-hero"
        isLoading={isPending}
        mt={3}
      >
        Tarkista
      </Button>
    </Box>
  );
}
