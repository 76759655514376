import React, { useContext, useRef, useState } from 'react';
import { Box, Button } from '@mezzoforte/forge';
import { X, MagnifyingGlass } from '@mezzoforte/forge-icons';
import { PrismicSettingsContext } from '../prismic/prismic-settings-context';
import { SearchForm } from './SearchForm';
import { ShowResponsive } from './ShowResponsive';

export const HeaderSearchForm = () => {
  const prismicSettings = useContext(PrismicSettingsContext);
  const [searchVisible, setSearchVisible] = useState(false);
  const input = useRef<HTMLInputElement>(null);

  function toggleSearch() {
    setSearchVisible(v => !v);
    if (!searchVisible) input.current?.focus();
  }

  return (
    <Box>
      <Box
        opacity={{ base: searchVisible ? 1 : 0.5, lg: 1 }}
        position={{ base: 'absolute', lg: 'static' }}
        right="66px"
        left={0}
        transform={{
          base: searchVisible ? 'none' : 'translateX(-1000em)',
          lg: 'none',
        }}
      >
        <SearchForm
          ref={input}
          placeholder={prismicSettings?.data.search_placeholder || ''}
        />
      </Box>
      <ShowResponsive lgHide>
        <Button
          backgroundColor="transparent"
          icon={searchVisible ? <X /> : <MagnifyingGlass />}
          aria-label="Näytä haku"
          onClick={toggleSearch}
          data-test="mobile-search-toggle"
          _active={{ background: 'black' }}
          _hover={{ background: 'black' }}
          css={{ '& svg': { fill: 'white' } }}
        />
      </ShowResponsive>
    </Box>
  );
};
