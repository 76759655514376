// Removed Flow types

import { Map, List } from 'immutable';

import netAuctionFormService from '../src/net-auction/net-auction-form-service';
import companyService from '../src/company/company-service';
import draftService from '../src/net-auction/draft-service';
import draftMediaService from '../src/media/draft-media-service';
import netAuctionMediaService from '../src/media/net-auction-media-service';
import { NetAuctionDraftType } from '../../typings/NetAuctionDraft';

const CHECK_BIDS = 'huutokaupat/net-auction/form/CHECK_BIDS';
const CHECK_BIDS_DONE = 'huutokaupat/net-auction/form/CHECK_BIDS_DONE';
const CHECK_BIDS_FAILED = 'huutokaupat/net-auction/form/CHECK_BIDS_FAILED';
const FETCH_COMPANY_DATA = 'huutokaupat/net-auction/form/FETCH_COMPANY_DATA';
const FETCH_COMPANY_DATA_DONE = 'huutokaupat/net-auction/form/FETCH_COMPANY_DATA_DONE';
const FETCH_COMPANY_DATA_FAILED = 'huutokaupat/net-auction/form/FETCH_COMPANY_DATA_FAILED';
const FETCH_IMAGES = 'huutokaupat/net-auction/form/FETCH_IMAGES';
const FETCH_IMAGES_DONE = 'huutokaupat/net-auction/form/FETCH_IMAGES_DONE';
const FETCH_IMAGES_FAILED = 'huutokaupat/net-auction/form/FETCH_IMAGES_FAILED';
const DELETE_IMAGE = 'huutokaupat/net-auction/form/DELETE_IMAGE';
const DELETE_IMAGE_DONE = 'huutokaupat/net-auction/form/DELETE_IMAGE_DONE';
const DELETE_IMAGE_FAILED = 'huutokaupat/net-auction/form/DELETE_IMAGE_FAILED';
const DELETE_ALL_IMAGES = 'huutokaupat/net-auction/form/DELETE_ALL_IMAGES';
const DELETE_ALL_IMAGES_DONE = 'huutokaupat/net-auction/form/DELETE_ALL_IMAGES_DONE';
const DELETE_ALL_IMAGES_FAILED = 'huutokaupat/net-auction/form/DELETE_ALL_IMAGES_FAILED';
const ROTATE_IMAGE = 'huutokaupat/net-auction/form/ROTATE_IMAGE';
const ROTATE_IMAGE_DONE = 'huutokaupat/net-auction/form/ROTATE_IMAGE_DONE';
const ROTATE_IMAGE_FAILED = 'huutokaupat/net-auction/form/ROTATE_IMAGE_FAILED';
const ORDER_IMAGES = 'huutokaupat/net-auction/form/ORDER_IMAGES';
const QUEUE_PHOTO = 'huutokaupat/net-auction/form/QUEUE_PHOTO';
const UPLOAD_PHOTO = 'huutokaupat/net-auction/form/UPLOAD_PHOTO';
const UPLOAD_PHOTO_DONE = 'huutokaupat/net-auction/form/UPLOAD_PHOTO_DONE';
const UPLOAD_PHOTO_FAILED = 'huutokaupat/net-auction/form/UPLOAD_PHOTO_FAILED';
const UPLOAD_VIDEO = 'huutokaupat/net-auction/form/UPLOAD_VIDEO';
const UPLOAD_VIDEO_DONE = 'huutokaupat/net-auction/form/UPLOAD_VIDEO_DONE';
const UPLOAD_VIDEO_FAILED = 'huutokaupat/net-auction/form/UPLOAD_VIDEO_FAILED';
const RESET_VIDEO_UPLOAD = 'huutokaupat/net-auction/form/RESET_VIDEO_UPLOAD';
const REMOVE_FAILED_IMAGE = 'huutokaupat/net-auction/form/REMOVE_FAILED_IMAGE';
const FETCH_ATTACHMENTS = 'huutokaupat/net-auction/form/FETCH_ATTACHMENTS';
const FETCH_ATTACHMENTS_DONE = 'huutokaupat/net-auction/form/FETCH_ATTACHMENTS_DONE';
const FETCH_ATTACHMENTS_FAILED = 'huutokaupat/net-auction/form/FETCH_ATTACHMENTS_FAILED';
const UPLOAD_ATTACHMENT = 'huutokaupat/net-auction/form/UPLOAD_ATTACHMENT';
const UPLOAD_ATTACHMENT_DONE = 'huutokaupat/net-auction/form/UPLOAD_ATTACHMENT_DONE';
const UPLOAD_ATTACHMENT_FAILED = 'huutokaupat/net-auction/form/UPLOAD_ATTACHMENT_FAILED';
const REMOVE_FAILED_ATTACHMENT = 'huutokaupat/net-auction/form/REMOVE_FAILED_ATTACHMENT';
const DELETE_ATTACHMENT = 'huutokaupat/net-auction/form/DELETE_ATTACHMENT';
const DELETE_ATTACHMENT_DONE = 'huutokaupat/net-auction/form/DELETE_ATTACHMENT_DONE';
const DELETE_ATTACHMENT_FAILED = 'huutokaupat/net-auction/form/DELETE_ATTACHMENT_FAILED';
const ORDER_ATTACHMENTS = 'huutokaupat/net-auction/form/ORDER_ATTACHMENTS';
const CREATE_DRAFT_BY_COPYING_TEMPLATE = 'huutokaupat/net-auction/form/CREATE_DRAFT_BY_COPYING_TEMPLATE';
const CREATE_DRAFT_BY_COPYING_TEMPLATE_DONE = 'huutokaupat/net-auction/form/CREATE_DRAFT_BY_COPYING_TEMPLATE_DONE';
const CREATE_DRAFT_BY_COPYING_TEMPLATE_FAILED = 'huutokaupat/net-auction/form/CREATE_DRAFT_BY_COPYING_TEMPLATE_FAILED';
const CREATE_DRAFT_BY_COPYING_NET_AUCTION = 'huutokaupat/net-auction/form/CREATE_DRAFT_BY_COPYING_NET_AUCTION';
const CREATE_DRAFT_BY_COPYING_NET_AUCTION_DONE =
  'huutokaupat/net-auction/form/CREATE_DRAFT_BY_COPYING_NET_AUCTION_DONE';
const CREATE_DRAFT_BY_COPYING_NET_AUCTION_FAILED =
  'huutokaupat/net-auction/form/CREATE_DRAFT_BY_COPYING_NET_AUCTION_FAILED';
const FETCH_DRAFT = 'huutokaupat/net-auction/form/FETCH_DRAFT';
const FETCH_DRAFT_DONE = 'huutokaupat/net-auction/form/FETCH_DRAFT_DONE';
const FETCH_DRAFT_FAILED = 'huutokaupat/net-auction/form/FETCH_DRAFT_FAILED';
const DRAFT_SET_TO_FORM_VALUES = 'huutokaupat/net-auction/form/DRAFT_SET_TO_FORM_VALUES';
const CREATE_DRAFT = 'huutokaupat/net-auction/form/CREATE_DRAFT';
const CREATE_DRAFT_DONE = 'huutokaupat/net-auction/form/CREATE_DRAFT_DONE';
const CREATE_DRAFT_FAILED = 'huutokaupat/net-auction/form/CREATE_DRAFT_FAILED';
const UPDATE_DRAFT = 'huutokaupat/net-auction/form/UPDATE_DRAFT';
const UPDATE_DRAFT_DONE = 'huutokaupat/net-auction/form/UPDATE_DRAFT_DONE';
const UPDATE_DRAFT_FAILED = 'huutokaupat/net-auction/form/UPDATE_DRAFT_FAILED';
const FETCH_RECENT_DRAFT = 'huutokaupat/net-auction/form/FETCH_RECENT_DRAFT';
const FETCH_RECENT_DRAFT_DONE = 'huutokaupat/net-auction/form/FETCH_RECENT_DRAFT_DONE';
const FETCH_RECENT_DRAFT_FAILED = 'huutokaupat/net-auction/form/FETCH_RECENT_DRAFT_FAILED';
const PUBLISH_DRAFT = 'huutokaupat/net-auction/form/PUBLISH_DRAFT';
const PUBLISH_DRAFT_DONE = 'huutokaupat/net-auction/form/PUBLISH_DRAFT_DONE';
const PUBLISH_DRAFT_FAILED = 'huutokaupat/net-auction/form/PUBLISH_DRAFT_FAILED';
const FETCH_TEMPLATES = 'huutokaupat/net-auction/form/FETCH_TEMPLATES';
const FETCH_TEMPLATES_DONE = 'huutokaupat/net-auction/form/FETCH_TEMPLATES_DONE';
const FETCH_TEMPLATES_FAILED = 'huutokaupat/net-auction/form/FETCH_TEMPLATES_FAILED';
const RESET = 'huutokaupat/net-auction/form/RESET';

function getMediaService(type) {
  const MEDIA_SERVICES = {
    [netAuctionFormService.DRAFT_TYPE_DRAFT]: draftMediaService,
    [netAuctionFormService.DRAFT_TYPE_NET_AUCTION]: netAuctionMediaService,
  };

  return MEDIA_SERVICES[type];
}

/**
 * Move item inside a list to new Index
 * @param {List} value
 * @param {int} oldIndex
 * @param {int} newIndex
 */
export const moveItemInlist = (value, oldIndex, newIndex) => {
  if (!value || 0 > newIndex || value.size < newIndex) return value;
  const srcItem = value.get(oldIndex);
  return value.splice(oldIndex, 1).splice(newIndex, 0, srcItem);
};

const initialState = Map({
  loadingDraft: false,
  loadingImages: false,
  loadingAttachments: false,
  creatingDraft: false,
  createdDraftId: null,
  createdDraftUuid: null,
  fetchedDraftData: Map(),
  previouslySubmittedData: Map(),
  companyData: Map(),
  recentDraft: Map(),
  imagesQueuedForUpload: List(),
  images: List(),
  failedImages: List(),
  imagesUploadingCount: 0,
  attachments: List(),
  failedAttachments: List(),
  attachmentsUploadingCount: 0,
  uploadingVideo: false,
  uploadingVideoFailed: false,
  publishedEntries: List(),
  publishing: false,
  templates: List(),
  isCreatingCopy: false,
  templateType: null,
  fetchingError: false,
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_COMPANY_DATA:
      return state.set('companyData', Map({ id: action.payload }));

    case FETCH_COMPANY_DATA_DONE:
      return state.set('companyData', Map(action.payload));

    case FETCH_COMPANY_DATA_FAILED:
    case FETCH_DRAFT_FAILED:
    case CREATE_DRAFT_BY_COPYING_NET_AUCTION_FAILED:
    case CREATE_DRAFT_BY_COPYING_TEMPLATE_FAILED:
      return state.set('fetchingError', true);

    case FETCH_DRAFT:
    case CREATE_DRAFT_BY_COPYING_TEMPLATE:
    case CREATE_DRAFT_BY_COPYING_NET_AUCTION:
      return state.set('loadingDraft', true);

    case FETCH_DRAFT_DONE:
      return state.set('fetchedDraftData', Map(action.payload));

    case CREATE_DRAFT_BY_COPYING_TEMPLATE_DONE:
      return state
        .set('fetchedDraftData', Map(action.payload.draft))
        .set('createdDraftId', action.payload.draft.id)
        .set('templateType', action.payload.templateType);

    case CREATE_DRAFT_BY_COPYING_NET_AUCTION_DONE:
      return state.set('createdDraftId', action.payload.draft.id).set('isCreatingCopy', true);

    case FETCH_IMAGES:
      return state.set('loadingImages', true);

    case FETCH_IMAGES_DONE:
      return state.set('images', List(action.payload)).set('loadingImages', false);

    case FETCH_ATTACHMENTS:
      return state.set('loadingAttachments', true);

    case FETCH_ATTACHMENTS_DONE:
      return state.set('attachments', List(action.payload)).set('loadingAttachments', false);

    case DRAFT_SET_TO_FORM_VALUES:
      return state.set('loadingDraft', false);

    case CREATE_DRAFT:
      return state.set('creatingDraft', true);

    case CREATE_DRAFT_DONE:
      return state
        .set('creatingDraft', false)
        .set('createdDraftId', action.payload.id)
        .set('createdDraftUuid', action.payload.uuid);

    case CREATE_DRAFT_FAILED:
      return state.set('creatingDraft', false);

    case QUEUE_PHOTO:
      return state
        .update('imagesQueuedForUpload', imagesQueuedForUpload => imagesQueuedForUpload.push(action.payload))
        .update('images', images => images.push(action.payload));

    case UPLOAD_PHOTO:
      return state
        .update('imagesQueuedForUpload', imagesQueuedForUpload =>
          imagesQueuedForUpload.filter(image => image.path !== action.payload.path)
        )
        .update('imagesUploadingCount', count => count + 1);

    case UPLOAD_PHOTO_DONE:
      return state
        .update('images', images =>
          images
            .map(image => {
              if (image.id === action.payload.file.id) {
                return null;
              }

              if (!image.isUploading || image.thumbnail !== action.payload.originalFile.thumbnail) {
                return image;
              }

              return { ...action.payload.file, isExternal: false };
            })
            .filter(image => image)
        )
        .update('imagesUploadingCount', count => count - 1);

    case UPLOAD_PHOTO_FAILED:
      return state
        .update('failedImages', failedImages =>
          failedImages.push({
            file: action.payload.file,
            status: action.payload.error.response.status,
          })
        )
        .update('images', images => images.filter(image => image.thumbnail !== action.payload.file.thumbnail))
        .update('imagesUploadingCount', count => count - 1);

    case REMOVE_FAILED_IMAGE:
      return state.update('failedImages', failedImages =>
        failedImages.filter(image => image.file.thumbnail !== action.payload.file.thumbnail)
      );

    case DELETE_IMAGE:
      return state.update('images', images => images.filter(image => image.id !== action.payload.id));

    case ROTATE_IMAGE:
      return state.updateIn(
        ['images', state.get('images', List()).findIndex(image => image.id === action.payload.id)],
        image => ({
          ...image,
          isUploading: true,
        })
      );

    case ROTATE_IMAGE_DONE:
      return state.update('images', images =>
        images.map(image => {
          if (image.id === action.payload.file.id) {
            return action.payload.file;
          }

          return image;
        })
      );

    case ROTATE_IMAGE_FAILED:
      return state.updateIn(
        ['images', state.get('images', List()).findIndex(image => image.id === action.payload.id)],
        image => ({
          ...image,
          isUploading: false,
        })
      );

    case DELETE_ALL_IMAGES:
      return state.set('images', List());

    case UPLOAD_VIDEO:
      return state.set('uploadingVideo', true);

    case UPLOAD_VIDEO_DONE:
      return state.update('images', images => images.push(action.payload)).set('uploadingVideo', false);

    case UPLOAD_VIDEO_FAILED:
      return state.set('uploadingVideo', false).set('uploadingVideoFailed', true);

    case RESET_VIDEO_UPLOAD:
      return state.set('uploadingVideo', false).set('uploadingVideoFailed', false);

    case ORDER_IMAGES:
      return state.set('images', List(action.payload.images));

    case UPLOAD_ATTACHMENT:
      return state
        .update('attachments', attachments => attachments.push(action.payload))
        .update('attachmentsUploadingCount', count => count + 1);

    case UPLOAD_ATTACHMENT_DONE:
      return state
        .update('attachments', attachments =>
          attachments.map(attachment => {
            if (attachment.id || attachment.url !== action.payload.originalFile.url) {
              return attachment;
            }

            return { ...action.payload.file };
          })
        )
        .update('attachmentsUploadingCount', count => count - 1);

    case UPLOAD_ATTACHMENT_FAILED:
      return state
        .update('failedAttachments', failedAttachments =>
          failedAttachments.push({
            file: action.payload.file,
            status: action.payload.error.response.status,
          })
        )
        .update('attachments', attachments =>
          attachments.filter(attachment => attachment.filename !== action.payload.file.filename)
        )
        .update('attachmentsUploadingCount', count => count - 1);

    case REMOVE_FAILED_ATTACHMENT:
      return state.update('failedAttachments', failedAttachments =>
        failedAttachments.filter(attachment => attachment.file.filename !== action.payload.file.filename)
      );

    case DELETE_ATTACHMENT:
      return state.update('attachments', attachments =>
        attachments.filter(attachment => attachment.id !== action.payload.id)
      );

    case ORDER_ATTACHMENTS:
      return state.set('attachments', List(action.payload.attachments));

    case FETCH_RECENT_DRAFT_DONE:
      return state.set('recentDraft', Map(action.payload));

    case PUBLISH_DRAFT:
      return state.set('publishing', true);

    case PUBLISH_DRAFT_DONE:
      return state.set('publishing', false).set('publishedEntries', List(action.payload.publishedEntries || []));

    case PUBLISH_DRAFT_FAILED:
      return state.set('publishing', false).set('publishedEntries', List());

    case FETCH_TEMPLATES_DONE:
      return state.set('loadingTemplates', false).set('templates', List(action.payload));

    case RESET:
      return initialState;

    default:
      return state;
  }
};

export function fetchCompanyData(companyId) {
  return dispatch => {
    dispatch({ type: FETCH_COMPANY_DATA, payload: companyId });

    return companyService.fetchDataForNetAuctionForm(companyId).then(
      response => dispatch({ type: FETCH_COMPANY_DATA_DONE, payload: response }),
      error => dispatch({ type: FETCH_COMPANY_DATA_FAILED, payload: { error } })
    );
  };
}

export function fetchDraft(draftId) {
  return dispatch => {
    dispatch({ type: FETCH_DRAFT });

    return draftService.fetchDraft(draftId).then(
      response => dispatch({ type: FETCH_DRAFT_DONE, payload: { ...response.draft } }),
      error => dispatch({ type: FETCH_DRAFT_FAILED, payload: { error } })
    );
  };
}

export function fetchDraftByEntryId(entryId) {
  return dispatch => {
    dispatch({ type: FETCH_DRAFT });

    return draftService.fetchDraftByEntryId(entryId).then(
      response =>
        dispatch({
          type: FETCH_DRAFT_DONE,
          payload: {
            ...response.draft,
            isOwnPayment: response.isOwnPayment,
            isOwnPaymentChangeable: response.isOwnPaymentChangeable,
            isEnded: response.isEnded,
            continuousAuctionCount: response.continuousAuctionCount,
            continuousAuctionDays: response.continuousAuctionDays,
            isPublished: response.isPublished,
            billing: response.billing,
            hasCustomBadge: response.customBadge !== null,
            customBadge: response.customBadge,
          },
        }),
      error => dispatch({ type: FETCH_DRAFT_FAILED, payload: { error } })
    );
  };
}

export function checkIfEntryHasBids(entryId) {
  return dispatch => {
    dispatch({ type: CHECK_BIDS });

    return draftService.fetchDraftByEntryId(entryId).then(
      () =>
        dispatch({
          type: CHECK_BIDS_DONE,
          payload: {
            hasBids: true,
          },
        }),
      error => dispatch({ type: CHECK_BIDS_FAILED, payload: { error } })
    );
  };
}

export function updateNetAuction(entryId, formValues) {
  return dispatch => {
    dispatch({ type: PUBLISH_DRAFT, payload: formValues });

    return draftService.updateByEntryId(entryId, formValues).then(
      response => dispatch({ type: PUBLISH_DRAFT_DONE, payload: response }),
      error => dispatch({ type: PUBLISH_DRAFT_FAILED, payload: { error } })
    );
  };
}

export function updateAdminFields(entryId, formValues) {
  return dispatch => {
    dispatch({ type: UPDATE_DRAFT, payload: formValues });

    return draftService.updateAdminFieldsByEntryId(entryId, formValues).then(
      response => dispatch({ type: UPDATE_DRAFT_DONE, payload: response }),
      error => dispatch({ type: UPDATE_DRAFT_FAILED, payload: { error } })
    );
  };
}

export function finishLoadingDraft() {
  return { type: DRAFT_SET_TO_FORM_VALUES };
}

export function createDraft(formValues, companyId) {
  return dispatch => {
    dispatch({ type: CREATE_DRAFT, payload: formValues });

    return draftService.createDraft(formValues, companyId).then(
      response => dispatch({ type: CREATE_DRAFT_DONE, payload: response }),
      error => dispatch({ type: CREATE_DRAFT_FAILED, payload: { error } })
    );
  };
}

export function updateDraft(draftId, formValues, companyId) {
  return dispatch => {
    dispatch({ type: UPDATE_DRAFT, payload: formValues });

    return draftService.updateDraft(draftId, formValues, companyId).then(
      response => dispatch({ type: UPDATE_DRAFT_DONE, payload: response }),
      error => dispatch({ type: UPDATE_DRAFT_FAILED, payload: { error } })
    );
  };
}

export function updateDraftForceAppraisal(draftId) {
  return dispatch => {
    dispatch({ type: UPDATE_DRAFT });

    return draftService.updateDraftForceAppraisal(draftId).then(
      response => dispatch({ type: UPDATE_DRAFT_DONE, payload: { ...response.draft } }),
      error => dispatch({ type: UPDATE_DRAFT_FAILED, payload: { error } })
    );
  };
}

export function publishDraft(draftId, formValues) {
  return dispatch => {
    dispatch({ type: PUBLISH_DRAFT });

    return draftService.publishDraft(draftId, formValues).then(
      response => dispatch({ type: PUBLISH_DRAFT_DONE, payload: response }),
      error => dispatch({ type: PUBLISH_DRAFT_FAILED, payload: { error } })
    );
  };
}

export function fetchImages(id, draftType) {
  return dispatch => {
    dispatch({ type: FETCH_IMAGES });

    return getMediaService(draftType)
      .fetchImages(id)
      .then(
        response => dispatch({ type: FETCH_IMAGES_DONE, payload: response.data }),
        error => dispatch({ type: FETCH_IMAGES_FAILED, payload: { error } })
      );
  };
}

export function fetchAttachments(id, draftType) {
  return dispatch => {
    dispatch({ type: FETCH_ATTACHMENTS });

    return getMediaService(draftType)
      .fetchAttachments(id)
      .then(
        response => dispatch({ type: FETCH_ATTACHMENTS_DONE, payload: response.data }),
        error => dispatch({ type: FETCH_ATTACHMENTS_FAILED, payload: { error } })
      );
  };
}

export function queueExternalPhotoForUpload(id, source, image, draftType) {
  return dispatch => {
    const file = {
      isExternal: true,
      path: image.path,
      thumbnail: image.path,
      type: draftMediaService.DRAFT_MEDIA_TYPE_IMAGE,
      draftType,
      draftId: id,
      source,
      image,
      isUploading: true,
    };

    return dispatch({ type: QUEUE_PHOTO, payload: file });
  };
}

export function queuePhotoForUpload(id, file, draftType) {
  return dispatch => {
    /* eslint-disable no-param-reassign */
    file.draftId = id;
    file.draftType = draftType;
    /* eslint-enable no-param-reassign */
    return dispatch({ type: QUEUE_PHOTO, payload: file });
  };
}

function uploadPhoto(id, file, draftType) {
  return dispatch => {
    dispatch({ type: UPLOAD_PHOTO, payload: file });

    const data = new FormData();
    data.append('file', file);

    return getMediaService(draftType)
      .uploadPhoto(id, data)
      .then(
        response =>
          dispatch({
            type: UPLOAD_PHOTO_DONE,
            payload: { file: response.data, originalFile: file },
          }),
        error => dispatch({ type: UPLOAD_PHOTO_FAILED, payload: { file, error } })
      );
  };
}

function uploadExternalPhoto(file) {
  return dispatch => {
    dispatch({ type: UPLOAD_PHOTO, payload: file });

    return getMediaService(file.draftType)
      .uploadExternalPhoto(file.draftId, file.source, file.image)
      .then(
        response =>
          dispatch({
            type: UPLOAD_PHOTO_DONE,
            payload: { file: response.data, originalFile: file },
          }),
        error => dispatch({ type: UPLOAD_PHOTO_FAILED, payload: { file, error } })
      );
  };
}

export function dequeuePhotoForUpload(file) {
  if (file.isExternal) {
    return uploadExternalPhoto(file);
  }

  return uploadPhoto(file.draftId, file, file.draftType);
}

export function removeFailedImage(failedImage) {
  return { type: REMOVE_FAILED_IMAGE, payload: failedImage };
}

export function deleteImage(file, draftType) {
  return dispatch => {
    dispatch({ type: DELETE_IMAGE, payload: file });

    return getMediaService(draftType)
      .deleteImage(file.id)
      .then(
        response =>
          dispatch({
            type: DELETE_IMAGE_DONE,
            payload: { file: response.data },
          }),
        error => dispatch({ type: DELETE_IMAGE_FAILED, payload: { file, error } })
      );
  };
}

export function deleteAllImages(draftId, draftType) {
  return dispatch => {
    dispatch({ type: DELETE_ALL_IMAGES });

    return getMediaService(draftType)
      .deleteAllImages(draftId)
      .then(
        () => dispatch({ type: DELETE_ALL_IMAGES_DONE }),
        error => dispatch({ type: DELETE_ALL_IMAGES_FAILED, payload: { error } })
      );
  };
}

export function rotateImage(file, draftType) {
  return dispatch => {
    dispatch({ type: ROTATE_IMAGE, payload: file });

    return getMediaService(draftType)
      .rotateImage(file.id)
      .then(
        response =>
          dispatch({
            type: ROTATE_IMAGE_DONE,
            payload: { file: response.data },
          }),
        error => dispatch({ type: ROTATE_IMAGE_FAILED, payload: { file, error } })
      );
  };
}

export function orderImages(id, images, draftType) {
  return (dispatch, getState) => {
    if (getState().netAuctionForm.get('imagesUploadingCount') === 0) {
      dispatch({ type: ORDER_IMAGES, payload: { images } });

      return getMediaService(draftType).orderImages(
        id,
        images.filter(image => !image.isUploading).map(image => image.id)
      );
    }
  };
}

export function uploadVideo(id, videoUrl, draftType) {
  return dispatch => {
    dispatch({ type: UPLOAD_VIDEO });

    return getMediaService(draftType)
      .uploadVideo(id, videoUrl)
      .then(
        response => dispatch({ type: UPLOAD_VIDEO_DONE, payload: response.data }),
        error => dispatch({ type: UPLOAD_VIDEO_FAILED, payload: error })
      );
  };
}

export function removeFailedVideo() {
  return { type: RESET_VIDEO_UPLOAD };
}

export function uploadAttachment(id, file, draftType, metadata = null) {
  return dispatch => {
    dispatch({
      type: UPLOAD_ATTACHMENT,
      payload: { filename: file.filename, url: file.url, metadata },
    });

    const data = new FormData();
    data.append('file', file);
    if (metadata) {
      data.append('metadata', JSON.stringify(metadata));
    }

    return getMediaService(draftType)
      .uploadAttachment(id, data)
      .then(
        response =>
          dispatch({
            type: UPLOAD_ATTACHMENT_DONE,
            payload: { file: response.data, originalFile: file },
          }),
        error => dispatch({ type: UPLOAD_ATTACHMENT_FAILED, payload: { file, error } })
      );
  };
}

export function removeFailedAttachment(failedAttachment) {
  return { type: REMOVE_FAILED_ATTACHMENT, payload: failedAttachment };
}

export function deleteAttachment(file, draftType) {
  return dispatch => {
    dispatch({ type: DELETE_ATTACHMENT, payload: file });

    return getMediaService(draftType)
      .deleteAttachment(file.id)
      .then(
        response =>
          dispatch({
            type: DELETE_ATTACHMENT_DONE,
            payload: { file: response.data },
          }),
        error => dispatch({ type: DELETE_ATTACHMENT_FAILED, payload: { file, error } })
      );
  };
}

export function orderAttachments(id, attachments, draftType) {
  return (dispatch, getState) => {
    if (getState().netAuctionForm.get('attachmentsUploadingCount') === 0) {
      dispatch({ type: ORDER_ATTACHMENTS, payload: { attachments } });

      return getMediaService(draftType).orderAttachments(
        id,
        attachments.map(attachment => attachment.id)
      );
    }
  };
}

export function fetchRecentDraft() {
  return dispatch => {
    dispatch({ type: FETCH_RECENT_DRAFT });

    return draftService.fetchRecentDraft().then(
      response => dispatch({ type: FETCH_RECENT_DRAFT_DONE, payload: response }),
      error => dispatch({ type: FETCH_RECENT_DRAFT_FAILED, payload: { error } })
    );
  };
}

export function fetchTemplates(companyId) {
  return dispatch => {
    dispatch({ type: FETCH_TEMPLATES });

    return draftService.fetchDraftsForAdminList(companyId, NetAuctionDraftType.Template).then(
      response => dispatch({ type: FETCH_TEMPLATES_DONE, payload: response }),
      error => dispatch({ type: FETCH_TEMPLATES_FAILED, payload: { error } })
    );
  };
}

export function createDraftFromTemplate(templateId, existingDraftId) {
  return dispatch => {
    dispatch({ type: CREATE_DRAFT_BY_COPYING_TEMPLATE });

    return draftService.createDraftFromTemplate(templateId, existingDraftId).then(
      response =>
        Promise.all([
          dispatch({
            type: CREATE_DRAFT_BY_COPYING_TEMPLATE_DONE,
            payload: response,
          }),
          response.draft.id && dispatch(fetchImages(response.draft.id, netAuctionFormService.DRAFT_TYPE_DRAFT)),
          response.draft.id && dispatch(fetchAttachments(response.draft.id, netAuctionFormService.DRAFT_TYPE_DRAFT)),
        ]),
      error =>
        dispatch({
          type: CREATE_DRAFT_BY_COPYING_TEMPLATE_FAILED,
          payload: { error },
        })
    );
  };
}

export function createDraftByCopyingNetAuction(entryId, copyMedias) {
  return dispatch => {
    dispatch({ type: CREATE_DRAFT_BY_COPYING_NET_AUCTION });

    return draftService.createDraftByCopyingNetAuction(entryId, copyMedias).then(
      response =>
        dispatch({
          type: CREATE_DRAFT_BY_COPYING_NET_AUCTION_DONE,
          payload: response,
        }),
      error =>
        dispatch({
          type: CREATE_DRAFT_BY_COPYING_NET_AUCTION_FAILED,
          payload: { error },
        })
    );
  };
}

export function resetForm() {
  return dispatch =>
    dispatch({
      type: RESET,
    });
}
