import { useTokens } from '@mezzoforte/forge';
import { CheckCircle, Icon, Warning, WarningCircle } from '@mezzoforte/forge-icons';
import { CompanyStatus } from '@typings/Company';

const { colors } = useTokens.huutokaupat;

export type CompanyStatusType = 'success' | 'warning' | 'danger';

export const companyStatusIcons: Record<CompanyStatusType, Icon> = {
  success: CheckCircle,
  warning: Warning,
  danger: WarningCircle,
};

export const statusStrings = new Map<number, string>([
  [CompanyStatus.Active, 'Aktiivinen'],
  [CompanyStatus.NoUsers, 'Ei käyttäjiä'],
  [CompanyStatus.UserNotEnabled, 'Käyttäjä estetty'],
  [CompanyStatus.UserNotIdentified, 'Odottaa tunnistautumista'],
  [CompanyStatus.WaitingForApproval, 'Odottaa hyväksyntää'],
  [CompanyStatus.Terminated, 'Yhteistyö päätetty'],
]);

export const statusColors = new Map<number[], string>([
  [[CompanyStatus.UserNotEnabled, CompanyStatus.NoUsers, CompanyStatus.Terminated], colors.danger],
  [[CompanyStatus.Active], colors.success],
  [[CompanyStatus.UserNotIdentified, CompanyStatus.WaitingForApproval], colors.text],
]);

export const statusIconType = new Map<number[], CompanyStatusType>([
  [[CompanyStatus.UserNotEnabled, CompanyStatus.NoUsers, CompanyStatus.Terminated], 'danger'],
  [[CompanyStatus.Active], 'success'],
  [[CompanyStatus.UserNotIdentified, CompanyStatus.WaitingForApproval], 'warning'],
]);

export const getStatusIconType = (status: string | number) => {
  const statusKey = typeof status === 'string' ? parseInt(status, 10) : status;
  const iconTypeKeys = [...statusIconType.keys()].find(it => it.includes(statusKey));
  return (iconTypeKeys && statusIconType.get(iconTypeKeys)) ?? 'default';
};

// types indicates that status = string, when mostly number - ensure to support both just incase
export const getStatusStringAndColor = (status: string | number) => {
  const statusKey = typeof status === 'string' ? parseInt(status, 10) : status;
  const colorKeys = [...statusColors.keys()].find(ck => ck.includes(statusKey));
  const color = (colorKeys && statusColors.get(colorKeys)) ?? colors.text;
  return {
    text: statusStrings.get(statusKey) ?? '',
    color,
  };
};
