import { formatNumber, unformat } from 'accounting';

export function formatValue(value: number | string, thousand = ' ', decimal = ',', precision: number = 2) {
  return formatNumber(Number(value), precision, thousand, decimal);
}

export function parseValue(value: number | string, decimal = ',') {
  if (typeof value === 'number') {
    return value;
  }
  return unformat(value, decimal);
}

function countDecimals(value: number) {
  return Math.floor(value) !== value ? value.toString().split('.')[1]?.length || 0 : 0;
}

export function simplifyCurrency(value: string | number) {
  const precision = countDecimals(Number(value));
  // Using 4 decimals for currency here is intentional.
  // This is meant to prevent rounding orders when calculating the VAT-included price.
  //
  // See https://mezzoforte.atlassian.net/browse/HKK-316 for details.
  //
  // This function is used in quite a few places, so it's possible that
  // 4-decimal currencies are shown where not intended. Fix those if
  // any are found.
  const formattedValue = formatValue(value, ' ', ',', Math.min(4, Math.max(precision, 2)));

  if (!formattedValue.includes(',')) {
    return formattedValue;
  }

  return formattedValue.replace(/,00$/, '');
}

export function simplifyPercent(value: string | number) {
  const formattedValue = formatValue(value, ' ', ',', 4);

  if (!formattedValue.includes(',')) {
    return formattedValue;
  }

  return formattedValue.replace(/0+$/, '').replace(/,+$/, '');
}

function addPrecisionToExponent(value: number, precision: number): number {
  const [number, exponent] = value.toExponential().split('e').map(Number);

  return Number(`${number}e${precision + exponent}`);
}

export function round(value: number, precision: number = 4): number {
  return (
    addPrecisionToExponent(Math.round(addPrecisionToExponent(Math.abs(value), precision)), precision * -1) *
    Math.sign(value)
  );
}
