import createClient from '../api/client';
import { formatDate } from '@app/src/util/date';
import { UserAgentHistory } from '@typings/UserAgentHistory';
import { UserEmail, UserEmailAdminOverride, UserEmailStatus } from '@typings/UserEmail';
import { Company } from '@typings/Company';
import { AdminUser, BuyerUser } from '@typings/User';

export const userImportFields = {
  email: 'Sähköposti',
  firstName: 'Etunimi',
  lastName: 'Sukunimi',
  address: 'Osoite',
  zipcode: 'Postinumero',
  postOffice: 'Postitoimipaikka',
  country: 'Maakoodi',
  phoneNumber: 'Puhelinnumero',
  buyer: 'Ostajan oikeudet',
  seller: 'Ilmoittajan oikeudet',
  buyerCompanyBusinessId: 'Ostajan Y-tunnus',
  buyerCompanyName: 'Ostajan yrityksen nimi',
  sellerCompanyId: 'Ilmoittajan yritys',
  skipIdentification: 'Ohita tunnistautuminen',
};

export const MaxLoginAttempts = 30;

export interface UnpaidEntry {
  readonly id: number;
  readonly amountDue: {
    readonly amount: number;
  };
  readonly title: string;
  readonly auctionEnd: string;
  readonly mainPhoto: string;
}

export interface ContactInfoRequestBody {
  readonly phoneNumber: string;
  readonly address: string;
  readonly postOffice: string;
  readonly zipcode: string;
  readonly iban?: string;
}

export interface FetchContactInfoResponse {
  readonly phoneNumber: string;
  readonly address: string;
  readonly postOffice: string;
  readonly zipcode: string;
  readonly consumerCompany?: { readonly bankAccount?: string };
}

interface RecentSoldEntry {
  readonly id: number;
  readonly title: string;
  readonly bidVerdictDone: string | null;
  readonly ownPayment: 'Y' | 'N';
  readonly netAuctionReturnId: number | null;
  readonly totalSum: string;
  readonly paymentTotalSum: string;
  readonly paymentDone: string | null;
  readonly receiptCompanyBusinessId: string | null;
  readonly isEveryReturnRequirementFilled: boolean;
}

interface SuccessResponse {
  readonly success: true;
}

export const GetEmailStatus = (userEmail: UserEmail, bounceReason: string | null | undefined) => {
  if (userEmail.confirmed === UserEmailStatus.UserEmailExpired) {
    return 'Vanhentunut';
  }

  if (userEmail.confirmed === UserEmailStatus.UserEmailConfirmed) {
    return `Vahvistettu ${
      userEmail.adminOverride === UserEmailAdminOverride.UserEmailAdminOverrideTrue ? 'adminin toimesta' : ''
    } ${userEmail.confirmationTime ? formatDate(userEmail.confirmationTime) : ''}`;
  }

  if (userEmail.confirmed === UserEmailStatus.UserEmailUnconfirmed) {
    return `Ei vahvistettu${
      userEmail.confirmationSent ? `, pyyntö lähetetty: ${formatDate(userEmail.confirmationSent)}` : ''
    }`;
  }

  if (userEmail.confirmed === UserEmailStatus.UserEmailBanned) {
    return `Deaktivoitu, sähköpostia ei voitu lähettää. ${bounceReason ?? ''}`;
  }

  return 'Tuntematon tila';
};

export function isAdminUser(user: AdminUser | BuyerUser): user is AdminUser {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- Type guard
  return (user as AdminUser).id !== undefined;
}

export default {
  // TODO: Incomplete typing
  fetch: async (userId: number) =>
    createClient()
      .get<BuyerUser | AdminUser>(`/users/${userId}`)
      .then(response => response.data),

  importUsers: async (users: Record<string, unknown>) =>
    createClient()
      .post<SuccessResponse>('/users/import', { usersToImport: users })
      .then(response => response.data),

  fetchSoldEntries: async (id: number) =>
    createClient()
      .get<unknown>(`/users/${id}/sold-entries`)
      .then(response => response.data),

  fetchWonEntries: async (id: number, type?: string) =>
    createClient()
      .get<unknown>(`/users/${id}/won-entries`, { params: { type } })
      .then(response => response.data),

  sendConfirmationEmail: async (userId: number) =>
    createClient()
      .post<SuccessResponse>(`/users/${userId}/send-confirmation-email`)
      .then(response => response.data),

  disableEntryResaleNotifications: async () =>
    createClient()
      .patch<SuccessResponse>('/users/disable-entry-resale-notifications')
      .then(response => response.data),

  changePassword: async ({
    userId,
    ...rest
  }: {
    readonly userId: number;
    readonly currentPassword: string;
    readonly newPassword: string;
  }) =>
    createClient()
      .patch<SuccessResponse>(`users/${userId}/password`, rest)
      .then(response => response.data),

  recoverPassword: async ({ password }: { readonly password: string }) =>
    createClient()
      .patch<SuccessResponse>(`users/password-recover`, {
        password,
      })
      .then(response => response.data),

  checkSessionPasswordToken: async () =>
    createClient()
      .get<{ readonly email: string }>(`users/password-recover/check`)
      .then(response => response.data),

  changeEmailAddress: async ({
    userId,
    password,
    email,
  }: {
    readonly userId: number;
    readonly password: string;
    readonly email: string;
  }) =>
    createClient()
      .patch<SuccessResponse>(`/users/${userId}/email`, { password, email })
      .then(response => response.data),

  fetchContactInfo: async (userId: number) =>
    createClient()
      .get<FetchContactInfoResponse>(`/users/${userId}/contact-info`)
      .then(response => response.data),

  updateContactInfo: async (userId: number, values: ContactInfoRequestBody) =>
    createClient()
      .patch<SuccessResponse>(`/users/${userId}/contact-info`, { ...values })
      .then(response => response.data),

  detachBuyerCompany: async (userId: number) =>
    createClient()
      .patch<SuccessResponse>(`/users/${userId}/buyer-company/detach`)
      .then(response => response.data),

  attachSellerCompanyAsBuyerCompany: async (userId: number, companyId: number) =>
    createClient()
      .patch<SuccessResponse>(`/users/${userId}/buyer-company/attach-seller-company-as-buyer`, { companyId })
      .then(response => response.data),

  fetchRecentSoldEntries: async (id: number) =>
    createClient()
      .get<RecentSoldEntry[]>(`/users/${id}/recent-sold-entries`)
      .then(response => response.data),

  fetchSessionCount: async (userId: number) =>
    createClient()
      .get<number>(`/users/${userId}/session-count`)
      .then(response => response.data),

  logoutOtherSessions: async (userId: number, password: string) =>
    createClient()
      .post<SuccessResponse>(`/users/${userId}/logout-other-sessions`, { password })
      .then(response => response.data),

  sendPasswordRecovery: async (userId: number, type: string) =>
    createClient('')
      .post<{ readonly result: boolean }>('/login/reset/password', {
        id: userId,
        type: type,
      })
      .then(response => response.data),

  gdprDelete: async (userId: number) =>
    createClient()
      .post<{ readonly success: boolean }>(`users/${userId}/gdpr-delete`)
      .then(response => response.data),

  resetLoginAttempts: async (userId: number) =>
    createClient()
      .post<{ readonly success: boolean }>(`users/${userId}/reset-login`)
      .then(response => response.data),

  enableTwoFactor: async (userId: number) =>
    createClient()
      .put<{ readonly message?: string; readonly success: boolean }>(`/two-factor/enable/${userId}`)
      .then(response => response.data),

  disableTwoFactor: async (userId: number) =>
    createClient()
      .put<{ readonly success: boolean }>(`/two-factor/disable/${userId}`)
      .then(response => response.data),

  getUserAgentHistory: async (userId: number) =>
    createClient()
      .get<UserAgentHistory[]>(`/users/${userId}/user-agent-history`)
      .then(response => response.data),

  getUserEmails: async (userId: number) =>
    createClient()
      .get<UserEmail[]>(`/users/${userId}/user-emails`)
      .then(response => response.data),

  getBounceReason: async (userId: number) =>
    createClient()
      .get<string | null>(`/users/${userId}/bounce-reason`)
      .then(response => response.data),

  getUserCompanies: async (userId: number) =>
    createClient()
      .get<Company[]>(`/users/${userId}/companies`)
      .then(response => response.data),

  addUserCompany: async ({ userId, companyId }: { readonly userId: number; readonly companyId: number }) =>
    createClient()
      .post<Company[]>(`/users/${userId}/companies`, { companyId })
      .then(response => response.data),

  removeUserCompany: async ({ userId, companyId }: { readonly userId: number; readonly companyId: number }) =>
    createClient()
      .delete<Company[]>(`/users/${userId}/companies/${companyId}`)
      .then(response => response.data),

  activateEmail: async (userId: number) =>
    createClient()
      .post<{ readonly success: boolean }>(`/users/${userId}/activate-email`)
      .then(response => response.data),
};
