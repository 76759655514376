import React, { ReactNode } from 'react';
import { Alert, AlertProps, Text, Link } from '@mezzoforte/forge';
import { LoginModalError } from './LoginModal';
import { CustomerServiceDetails } from '../customer-service/CustomerServiceDetails';

const alertPropsByError: Record<LoginModalError, Partial<AlertProps>> = {
  banned: { variant: 'danger', title: 'Käyttäjän kirjautuminen on estetty' },
  failed: { variant: 'warning', title: 'Kirjautuminen epäonnistui', showIcon: false },
  'forced-password-reset': { variant: 'danger', title: 'Salasana on vaihdettava ennen kuin voit kirjautua sisään' },
  'internal-error': { variant: 'danger', title: 'Kirjautumisessa tapahtui virhe' },
  'ip-restricted': { variant: 'warning', title: 'Kirjautuminen epäonnistui', showIcon: false },
  'deletion-ongoing': { variant: 'warning', title: 'Käyttäjän poisto on käynnissä' },
};

const alertContentByError: Record<LoginModalError, ReactNode> = {
  'forced-password-reset': (
    <>
      <Text as="p">Saat vaihdettua salasanasi sähköpostiisi lähetetyn linkin avulla.</Text>
      <Text as="p">Ongelmatilanteissa voit olla yhteydessä asiakaspalveluumme:</Text>
      <CustomerServiceDetails />
    </>
  ),
  failed: (
    <Link
      mt={2}
      href="/unohditko-salasanasi"
    >
      Ongelmia kirjautumisessa
    </Link>
  ),
  'internal-error': (
    <>
      <Text as="p">Ongelmatilanteissa voit olla yhteydessä asiakaspalveluumme:</Text>
      <CustomerServiceDetails />
    </>
  ),
  banned: (
    <>
      <Text as="p">Ongelmatilanteissa voit olla yhteydessä asiakaspalveluumme:</Text>
      <CustomerServiceDetails />
    </>
  ),
  'ip-restricted': (
    <Text as="p">Ylläpitäjänä voit kirjautua vain sallituista IP-osoitteista. Kokeile yhdistämistä VPN-verkkoon.</Text>
  ),
  'deletion-ongoing': (
    <>
      <Text as="p">Ongelmatilanteissa voit olla yhteydessä asiakaspalveluumme:</Text>
      <CustomerServiceDetails />
    </>
  ),
};

export interface LoginErrorProps {
  readonly error: LoginModalError;
}

export function LoginError({ error }: LoginErrorProps) {
  return (
    <Alert
      {...alertPropsByError[error]}
      mb={5}
      px={4}
      data-test="hk-auth-alert"
    >
      {alertContentByError[error]}
    </Alert>
  );
}
