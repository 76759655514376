// @flow

import React from 'react';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import ValidationErrors from './validation/ValidationErrors';

type Meta = {
  touched?: boolean,
  error?: boolean,
  warning?: boolean,
};

export type Props = {
  input: any,
  label?: string,
  showViolations?: boolean,
  className: string,
  options: any[],
  meta: Meta,
  defaultSelection?: string,
  loading?: boolean,
  disabled?: boolean,
};

/** @deprecated use Forge components + react-hook-form */
const SelectField = ({
  input,
  label,
  className,
  showViolations,
  options,
  meta: { touched, error, warning },
  defaultSelection = 'Valitse',
  loading = false,
  disabled = false,
}: Props): React$Node => {
  const { name } = input;
  const id = name
    .replace('.', '-')
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .toLowerCase();
  const validation = ValidationErrors(error, warning, touched || showViolations);

  return (
    <FormGroup
      id={`${id}-container`}
      validationState={showViolations ? validation.state : null}
    >
      <ControlLabel>{label}</ControlLabel>
      {validation.message}
      <FormControl
        {...input}
        componentClass="select"
        className={className}
        id={id}
        disabled={disabled}
      >
        {loading && <option value="">Ladataan...</option>}
        {!loading && defaultSelection && (
          <>
            {defaultSelection && <option value="">{defaultSelection}</option>}
            {options.map(option => (
              <option
                value={option.value}
                key={option.name}
              >
                {option.name}
              </option>
            ))}
          </>
        )}
      </FormControl>
    </FormGroup>
  );
};

export default SelectField;
