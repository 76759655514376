/* eslint-disable @typescript-eslint/naming-convention -- keep consistent */
import { intersection } from 'lodash-es';

import metadataCategories from './metadata-categories';
import categoryService from '../category/category-service';
import netAuctionClassService from '../net-auction/class-service';

const INDUSTRIAL_MACHINERY_SUB_CATEGORIES = [
  metadataCategories.METADATA_CONSTRUCTION_MACHINERY,
  metadataCategories.METADATA_FARMING_MACHINERY,
  metadataCategories.METADATA_FOREST_MACHINERY,
  metadataCategories.METADATA_OTHER_MACHINERY,
];

export default {
  REGISTRATION_STATUS_IS_REGISTERED: '1',
  REGISTRATION_STATUS_NOT_REGISTERED: '2',
  REGISTRATION_STATUS_NOT_REQUIRED: '3',
  REGISTRATION_STATUS_PROFESSIONAL: '4',

  VEHICLE_INSURANCE_INSURED: '1',
  VEHICLE_INSURANCE_NOT_INSURED: '2',
  VEHICLE_INSURANCE_NOT_REQUIRED: '3',

  INSPECTION_STATUS_DONE: '1',
  INSPECTION_STATUS_NOT_DONE: '2',
  INSPECTION_STATUS_NOT_REQUIRED: '3',

  WITHDRAWN_FROM_TRAFFIC_YES: '1',
  WITHDRAWN_FROM_TRAFFIC_NO: '0',

  CE_MARKING_STATUS_YES: '1',
  CE_MARKING_STATUS_NO: '2',
  CE_MARKING_STATUS_NOT_REQUIRED: '3',

  DRIVE_FRONT_WHEEL_DRIVE: '1',
  DRIVE_REAR_WHEEL_DRIVE: '2',
  DRIVE_ALL_WHEEL_DRIVE: '3',
  DRIVE_TRACK_DRIVE: '4',
  DRIVE_TRACK_TOWABLE: '5',
  DRIVE_TRACK_OTHER: '99',

  FUEL_TYPE_GASOLINE: '1',
  FUEL_TYPE_DIESEL: '2',
  FUEL_TYPE_HYBRID: '3',
  FUEL_TYPE_GAS: '4',
  FUEL_TYPE_ELECTRICITY: '5',
  FUEL_TYPE_BIOFUEL: '6',
  FUEL_TYPE_OTHER: '99',

  POWER_TYPE_KW: 'kW',
  POWER_TYPE_HV: 'Hv',

  TRANSMISSION_MANUAL: '1',
  TRANSMISSION_AUTOMATIC: '2',

  IN_WORKING_ORDER_YES: '1',
  IN_WORKING_ORDER_NO: '0',

  MAINTENANCE_BOOK_COMPLETE: '1',
  MAINTENANCE_BOOK_PARTIAL: '2',
  MAINTENANCE_BOOK_MISSING: '3',

  LICENSED_TWICE_YES: '1',
  LICENSED_TWICE_NO: '0',

  IS_IMPORTED_YES: '1',
  IS_IMPORTED_NO: '0',

  IS_IMPORT_TAX_PAID_YES: '1',
  IS_IMPORT_TAX_PAID_NO: '0',

  TAXES_OVERDUE_YES: '1',
  TAXES_OVERDUE_NO: '0',

  FAULTY_KEY_REMOTE_WORKING: '1',
  FAULTY_KEY_REMOTE_NOT_WORKING: '2',
  FAULTY_KEY_REMOTE_NO_REMOTE: '3',

  FAULTY_WINDSHIELD_YES: '1',
  FAULTY_WINDSHIELD_NO: '0',

  CONTAINS_SUMMER_TIRES_YES: '1',
  CONTAINS_SUMMER_TIRES_NO: '0',

  ARE_SUMMER_TIRES_LEGAL_YES: '1',
  ARE_SUMMER_TIRES_LEGAL_NO: '0',

  CONTAINS_WINTER_TIRES_YES: '1',
  CONTAINS_WINTER_TIRES_NO: '0',

  ARE_WINTER_TIRES_LEGAL_YES: '1',
  ARE_WINTER_TIRES_LEGAL_NO: '0',

  FAULTY_EXTERIOR_YES: '1',
  FAULTY_EXTERIOR_NO: '0',

  FAULTY_INTERIOR_YES: '1',
  FAULTY_INTERIOR_NO: '0',

  WARNING_INDICATOR_YES: '1',
  WARNING_INDICATOR_NO: '0',

  DELIVERY_CONTAINS_ALL_ITEMS_YES: '1',
  DELIVERY_CONTAINS_ALL_ITEMS_NO: '0',

  VEHICLE_BREAKS_YES: '1',
  VEHICLE_BREAKS_NO: '0',

  APARTMENT_TYPE_PROPERTY: 1,
  APARTMENT_TYPE_CONDO: 2,
  APARTMENT_TYPE_ROWHOUSE: 3,
  APARTMENT_TYPE_DUPLEX: 4,
  APARTMENT_TYPE_HOUSE: 5,
  APARTMENT_TYPE_WOODEN_HOUSE: 6,
  APARTMENT_TYPE_DETACHED_HOUSE: 7,
  APARTMENT_TYPE_OTHER: 99,

  ROOM_COUNT_MORE_THAN_FIVE: '99',

  DEPOSIT_TYPE_NO_DEPOSIT: 1,
  DEPOSIT_TYPE_PERCENTAGE: 2,
  DEPOSIT_TYPE_FIXED_AMOUNT: 3,

  EMISSION_MANIPULATION_YES: true,

  SPECIAL_VEHICLE_YES: true,
  SPECIAL_VEHICLE_NO: false,

  getPossibleMetadatasForCategoryAndClass(categoryId: number, netAuctionClass: string) {
    return intersection(
      categoryService.getPossibleMetadatas(categoryId),
      netAuctionClassService.getPossibleMetadatas(netAuctionClass)
    );
  },

  getBaseMetadataCategory(metadataCategory: string) {
    if (INDUSTRIAL_MACHINERY_SUB_CATEGORIES.includes(metadataCategory)) {
      return 'IndustrialMachinery';
    }

    return null;
  },

  getFuelTypeLabel(fuelType?: string) {
    const fuelTypeMap = {
      [this.FUEL_TYPE_GASOLINE]: 'Bensiini',
      [this.FUEL_TYPE_DIESEL]: 'Diesel',
      [this.FUEL_TYPE_HYBRID]: 'Hybridi',
      [this.FUEL_TYPE_GAS]: 'Kaasu',
      [this.FUEL_TYPE_ELECTRICITY]: 'Sähkö',
      [this.FUEL_TYPE_BIOFUEL]: 'E85-bensiini',
      [this.FUEL_TYPE_OTHER]: 'Muu',
    };

    return !fuelType ? '' : fuelTypeMap[fuelType] || '';
  },

  getDriveLabel(drive?: string) {
    const driveMap = {
      [this.DRIVE_FRONT_WHEEL_DRIVE]: 'Etuveto',
      [this.DRIVE_REAR_WHEEL_DRIVE]: 'Takaveto',
      [this.DRIVE_ALL_WHEEL_DRIVE]: 'Neliveto',
      [this.DRIVE_TRACK_DRIVE]: 'Telaveto',
      [this.DRIVE_TRACK_TOWABLE]: 'Hinattava',
      [this.DRIVE_TRACK_OTHER]: 'Muu',
    };

    return !drive ? '' : driveMap[drive] || '';
  },

  getTransmissionLabel(drive?: string) {
    const transmissionMap = {
      [this.TRANSMISSION_MANUAL]: 'Manuaali',
      [this.TRANSMISSION_AUTOMATIC]: 'Automaatti',
    };

    return !drive ? '' : transmissionMap[drive] || '';
  },

  getInspectionStatusLabel(inspectionStatus: number) {
    const inspectionStatusMap = {
      [this.INSPECTION_STATUS_DONE]: 'Kyllä',
      [this.INSPECTION_STATUS_NOT_DONE]: 'Ei',
      [this.INSPECTION_STATUS_NOT_REQUIRED]: 'Ei vaadi katsastusta',
    };

    return inspectionStatusMap[inspectionStatus] || '';
  },

  getRegistrationStatusLabel(drive: string) {
    const transmissionMap = {
      [this.REGISTRATION_STATUS_IS_REGISTERED]: 'Kyllä',
      [this.REGISTRATION_STATUS_NOT_REGISTERED]: 'Ei',
      [this.REGISTRATION_STATUS_NOT_REQUIRED]: 'Ei vaadi rekisteröimistä',
      [this.REGISTRATION_STATUS_PROFESSIONAL]: 'Rekisteröity ammattikäyttöön',
    };

    return transmissionMap[drive] || '';
  },
};
