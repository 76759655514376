import React from 'react';
import { createLocation, Location } from 'history';
import { __RouterContext as RouterContext } from 'react-router';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { useTokens } from '@mezzoforte/forge';

const { colors } = useTokens.huutokaupat;

const absoluteUrl = /^https?:\/\//;

/**
 * Patterns that cannot be routed inside the client-side React application.
 * This includes routes that are handled by huutokaupat-frontend Next.js application.
 */
const unroutablePaths = [
  /^\/$/, // /
  /^\/kohde\/\d+\/?$/, // /kohde/:id
  /^\/kohde\/\d+\/[a-zA-Z0-9-_]+\/?$/, // /kohde/:id/:slug
  /^\/(tee-tunnus|tee-tunnus\/valmis)$/, // /tee-tunnus && /tee-tunnus/valmis
  /^\/evasteet$/, // /evasteet
  /^\/osasto\/?(\?.*)?$/, // /osasto
  /^\/osasto\/[a-zA-Z0-9-_]+\/?(\?.*)?$/, // /osasto/:slug
  /^\/osasto\/tapahtumat\/(\d+)\/?(\?.*)?$/, // /osasto/tapahtumat/:id
  /^\/haku\/?(\?.*)?$/, // /haku
  /^\/ilmoittaja\/[a-zA-Z0-9-_]+\/?(\?.*)?$/, // /ilmoittaja/:slug
  /^\/ilmoittaja\/[a-zA-Z0-9-_]+\/palautteet\/?$/, // /ilmoittaja/:slug/palautteet
  /^\/seurantalista\/?(\?.*)?$/, // /seurantalista
  /^\/maakunnat$/, // /maakunnat
  /^\/esikatsele\/\d+\/?/, // /esikatsele/:id
  /^\/ilmianna$/, // /ilmianna
  absoluteUrl, // External URLs
];

function isUnroutable(href: string) {
  return unroutablePaths.some(pattern => href.match(pattern));
}

function normalizeToLocation(to: RouterLinkProps['to'], currentLocation: Location) {
  if (typeof to === 'string') {
    if (absoluteUrl.test(to)) return createLocation(to);

    return createLocation(to, null, undefined, currentLocation);
  }
  if (typeof to === 'function') return createLocation(to(currentLocation), null, undefined, currentLocation);
  return to;
}

type LinkVariant = 'primary' | 'default';
export interface LinkProps extends RouterLinkProps {
  readonly linkVariant?: LinkVariant;
}

const linkColorStyles = (variant?: LinkVariant) => ({
  ...(variant && {
    style: {
      color: variant === 'primary' ? colors.brand : colors.text,
    },
  }),
});

export function Link({ linkVariant, to, ...props }: LinkProps) {
  return (
    <RouterContext.Consumer>
      {context => {
        const location = normalizeToLocation(to, context.location);
        const href = location ? context.history.createHref(location) : '';

        if (isUnroutable(href)) {
          return (
            <a
              href={href}
              {...linkColorStyles(linkVariant)}
              {...props}
            />
          );
        }
        return (
          <RouterLink
            to={to}
            {...linkColorStyles(linkVariant)}
            {...props}
          />
        );
      }}
    </RouterContext.Consumer>
  );
}
