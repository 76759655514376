// converted from Flow -> js

import { pickBy } from 'lodash-es';
import dayjs from 'dayjs';

import createClient from '../api/client';
import { dashboardEntryLimit } from './net-auction-service';
import netAuctionFormService from './net-auction-form-service';
import { formatDate } from '../util/date';
import { NetAuctionDraftType } from '../../../typings/NetAuctionDraft';

const stringifyDayjs = value => {
  if (!value || !value.isValid()) {
    return null;
  }

  return value.format();
};

function formatCopyValues(copyValues) {
  return Object.keys(copyValues).map(copyIndex => ({
    ...copyValues[copyIndex],
    auctionStart:
      copyValues[copyIndex].auctionStart instanceof dayjs ? copyValues[copyIndex].auctionStart.format() : null,
    auctionEnd: copyValues[copyIndex].auctionEnd.format(),
  }));
}

function formatValues(formValues) {
  const stringify = value => {
    if (value === null || value === undefined) {
      return null;
    }

    return String(value);
  };

  const formattedValues = {
    title: formValues.title ? formValues.title.slice(0, netAuctionFormService.MAX_TITLE_LENGTH) : null,
    streetAddress: formValues.streetAddress
      ? formValues.streetAddress.slice(0, netAuctionFormService.MAX_ADDRESS_LENGTH)
      : null,
    startPrice: stringify(formValues.startPrice),
    reservePrice: stringify(formValues.reservePrice),
    minBid: stringify(formValues.minBid),
    retrievalExpenses: stringify(formValues.retrievalExpenses),
    deliveryExpenses: stringify(formValues.deliveryExpenses),
    bookValue: stringify(formValues.bookValue),
    auctionStart: stringifyDayjs(formValues.auctionStart),
    auctionEnd: stringifyDayjs(formValues.auctionEnd),
    copy: formValues.copy ? formatCopyValues(formValues.copy) : null,
    isPrivateAuction: !!formValues.isPrivateAuction,
    ownPaymentOverride: !!formValues.ownPaymentOverride,
    isVerifiedEntry: !!formValues.isVerifiedEntry,
    isSpecialEntry: !!formValues.isSpecialEntry,
  };

  return {
    ...formValues,
    ...pickBy(formattedValues, value => value !== null),
  };
}

const integerify = value => (value === null ? null : parseInt(value, 10));
const numberify = value => (value === null ? null : Number(value));
const dayjsify = value => (value === null ? null : dayjs(value));
const ceilify = value => (value === null || Number.isNaN(value) ? null : Math.ceil(Number(value)));

function parseDraftToFormValues(draft) {
  const parsedValues = {
    noDefects: draft.noDefects && !netAuctionFormService.shouldForceDefects(draft?.category),
    startPrice: ceilify(draft.startPrice),
    reservePrice: integerify(draft.reservePrice),
    minBid: integerify(draft.minBid),
    retrievalExpenses: numberify(draft.retrievalExpenses),
    deliveryExpenses: numberify(draft.deliveryExpenses),
    bookValue: integerify(draft.bookValue),
    auctionStart: dayjsify(draft.auctionStart),
    auctionEnd: dayjsify(draft.auctionEnd),
    metadata: draft.metadata
      ? {
          ...draft.metadata,
          dealDueDate: dayjsify(draft.metadata.dealDueDate),
          managementCharge: draft.metadata.maintenanceFee + draft.metadata.loanPayment,
        }
      : null,
  };

  return {
    ...draft,
    ...parsedValues,
  };
}

export default {
  getDraftTitle: (draft, categories) => {
    if (draft.title) {
      return String(draft.title);
    }

    const selectedCategory = draft.category;

    const categoryName =
      selectedCategory && selectedCategory.id
        ? categories.find(category => category.id === selectedCategory.id).name
        : 'ei osastoa';

    return `Luonnos, ${formatDate(draft.createdAt)}, ${categoryName}`;
  },

  createDraftFromTemplate: (templateId, existingDraftId) => {
    const params = existingDraftId ? { existingDraftId } : {};

    return createClient()
      .post(`/net-auctions/drafts/from-template/${templateId}`, { params })
      .then(response => ({
        draft: parseDraftToFormValues(response.data.draft),
        templateType: response.data.templateType,
      }));
  },

  createDraftByCopyingNetAuction: (entryId, copyMedias) =>
    createClient()
      .post(`/net-auctions/drafts/copy/net-auction/${entryId}`, { copyMedias })
      .then(response => ({
        draft: parseDraftToFormValues(response.data.draft),
      })),

  fetchDraft: (id, uuid) =>
    createClient()
      .get(`/net-auctions/drafts/${id}`, uuid ? { params: { uuid } } : {})
      .then(response => ({
        draft: parseDraftToFormValues(response.data.draft),
      })),

  fetchDraftByEntryId: entryId =>
    createClient()
      .get(`/net-auctions/${entryId}/draft`)
      .then(response => ({
        draft: parseDraftToFormValues(response.data.draft),
        isOwnPayment: response.data.isOwnPayment,
        isEnded: response.data.isEnded,
        isOwnPaymentChangeable: response.data.isOwnPaymentChangeable,
        continuousAuctionCount: response.data.continuousAuctionCount,
        continuousAuctionDays: response.data.continuousAuctionDays,
        isPublished: response.data.isPublished,
        billing: response.data.billing,
        hasCustomBadge: response.data.hasCustomBadge,
        customBadge: response.data.customBadge,
      })),

  fetchDraftsForAdminList: (company = null, type = null, term = null, sellerType = null, limit = null) =>
    createClient()
      .post('/net-auctions/drafts/list', { company, type, term, limit, sellerType })
      .then(response => response.data),

  fetchDraftsForDashboard: (company = null, types = null, term = null, sellerType) =>
    Promise.all(
      types.map(type =>
        createClient().post('/net-auctions/drafts/list', {
          company,
          type: type === 'draft' ? NetAuctionDraftType.Draft : NetAuctionDraftType.Template,
          term,
          limit: dashboardEntryLimit,
          sellerType,
        })
      )
    ).then(responses =>
      responses.reduce(
        (result, response, index) => ({
          ...result,
          [types[index]]: response.data,
        }),
        {}
      )
    ),

  fetchRecentDraft: () =>
    createClient()
      .get('/net-auctions/drafts/recent')
      .then(response => response.data),

  fetchRecentConsumerDraft: () =>
    createClient()
      .get('/net-auctions/drafts/consumer/recent')
      .then(response => response.data),

  createDraft: (formValues, companyId) =>
    createClient()
      .post('/net-auctions/drafts', {
        formValues: formatValues(formValues),
        companyId,
      })
      .then(response => response.data),

  createConsumerDraftByCrawlingTrafi: (licenseNumber, vehicleType, odometer, appraisalClass) =>
    createClient()
      .post('/net-auctions/drafts/consumer/new', {
        licenseNumber,
        vehicleType,
        odometer,
        appraisalClass,
      })
      .then(response => response.data),

  updateDraft: (id, formValues, companyId) =>
    createClient()
      .put(`/net-auctions/drafts/${id}`, {
        formValues: formatValues(formValues),
        companyId,
      })
      .then(response => response.data),

  updateByEntryId: (entryId, formValues) =>
    createClient()
      .put(`/net-auctions/${entryId}/draft`, {
        formValues: formatValues(formValues),
      })
      .then(response => response.data),

  updateAdminFieldsByEntryId: (entryId, formValues) =>
    createClient()
      .put(`/net-auctions/${entryId}/admin-fields`, {
        formValues: formatValues(formValues),
      })
      .then(response => response.data),

  updateDraftForceAppraisal: draftId =>
    createClient()
      .post(`/net-auctions/drafts/consumer/update/${draftId}/new-appraisal`)
      .then(response => ({
        draft: parseDraftToFormValues(response.data.draft),
      })),

  publishDraft: (id, formValues) =>
    createClient()
      .put(`/net-auctions/drafts/publish/${id}`, {
        formValues: formatValues(formValues),
      })
      .then(response => response.data),

  markConsumerDraftAsReady: (id, formValues) =>
    createClient()
      .put(`/net-auctions/drafts/consumer/mark-as-ready/${id}`, {
        formValues: formatValues(formValues),
      })
      .then(response => response.data),

  deleteDraft: id =>
    createClient()
      .delete(`/net-auctions/drafts/${id}`)
      .then(response => response.data),

  deleteDrafts: draftIds =>
    createClient()
      .post(`/net-auctions/drafts/delete-many`, { draftIds })
      .then(response => response.data),

  fetchPreviewCompanyData: (id, uuid) =>
    createClient()
      .get(`/net-auctions/drafts/${id}/${uuid}/preview-company-data`)
      .then(response => response.data),

  getConsumerAppraisalsRemainingCount: () =>
    createClient()
      .get('/appraisal/consumer/remaining')
      .then(response => response.data),
};
