import { CompanySlug } from '@typings/CompanySlug';
import { CompanyTermsOfSale } from '@typings/CompanyTermsOfSale';
import { AdminUser } from '@typings/User';
import { CompanyDiscountTier } from '@app/src/company/company-summary-service';

export enum CompanyStatus {
  Active = 1,
  NoUsers = 2,
  UserNotEnabled = 3,
  UserNotIdentified = 4,
  WaitingForApproval = 5,
  Terminated = 9,
}

export interface Company {
  readonly accountingEmails: string[];
  readonly activeDiscountTiers: CompanyDiscountTier[];
  readonly approvalNotes: string;
  readonly bankAccount: string;
  readonly businessId: string;
  readonly canSkipDsa: boolean;
  readonly customerfundsMessageType: 'M' | 'R';
  readonly description: string;
  readonly displayReferenceNumber: 'Y' | 'N';
  readonly enableCustomerfundsProvision: boolean;
  readonly entryCount: number;
  readonly id: number;
  readonly isBookValueEnabled: boolean;
  readonly isBusinessReportEnabled: boolean;
  readonly isConsumer: boolean;
  readonly monitoringStatus: boolean;
  readonly monitoringTransferredAt: string | null;
  readonly name: string;
  readonly netAuctionFeedbackRecipients: string[] | null;
  readonly slug: CompanySlug;
  readonly status: CompanyStatus;
  readonly terminated: boolean;
  readonly termsOfSaleList: CompanyTermsOfSale[];
  readonly tikonIds: string[];
  readonly type: string;
  readonly userCount: number;
  readonly users?: CompanyUser[];
  readonly vatNumber: string;
  readonly internalDisplayName: string;
}

export interface SimpleCompany {
  readonly id: number;
  readonly name: string;
}

export interface CompanyUser {
  readonly company: Company;
  readonly dsaAcceptedAt?: string;
  readonly user?: AdminUser;
}
