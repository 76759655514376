export enum UserEmailStatus {
  UserEmailConfirmed = 'Y',
  UserEmailUnconfirmed = 'N',
  UserEmailBanned = 'B',
  UserEmailExpired = 'E',
}
export enum UserEmailAdminOverride {
  UserEmailAdminOverrideTrue = 'Y',
  UserEmailAdminOverrideFalse = 'N',
}

export interface UserEmail {
  readonly adminOverride: string;
  readonly confirmationSent: string;
  readonly confirmationTime: string;
  readonly confirmed: UserEmailStatus;
  readonly newEmail: string;
  readonly previousEmail: string | null;
}
