import { intersection } from 'lodash-es';
import metadataCategories from '../metadata/metadata-categories';

const fieldNames = {
  TITLE: 'title',
  SUBTITLE: 'subtitle',
  METHOD_OF_SALES: 'methodOfSales',
  RESERVE_PRICE: 'reservePrice',
  START_PRICE: 'startPrice',
  MIN_BID: 'minBid',
  AUCTION_START: 'auctionStart',
  AUCTION_DURATION: 'auctionDuration',
  AUCTION_START_PREVIOUS: 'auctionStartPrevious',
  AUCTION_END_ORIGINAL: 'auctionEndOriginal',
  AUCTION_DURATION_ORIGINAL: 'auctionDurationOriginal',
  SHOULD_USE_AUCTION_DURATION: 'shouldUseAuctionDuration',
  AUCTION_END: 'auctionEnd',
  CONTINUOUS_AUCTION: 'continuousAuction',
  VAT_PERCENT: 'vatPercent',
  CATEGORY: 'category',
  CATEGORY_ID: 'category.id',
  PARENT_CATEGORY: 'category.parentCategory',
  METADATA_APARTMENT_TYPE: 'metadata.apartmentType',
  METADATA_ROOM_COUNT: 'metadata.roomCount',
  METADATA_LIVING_AREA: 'metadata.livingArea',
  METADATA_CONSTRUCTION_YEAR: 'metadata.constructionYear',
  METADATA_MAINTENANCE_FEE: 'metadata.maintenanceFee',
  METADATA_LOAN_PAYMENT: 'metadata.loanPayment',
  METADATA_MANAGEMENT_CHARGE: 'metadata.managementCharge',
  METADATA_DEBT: 'metadata.debt',
  METADATA_DEAL_DUE_DATE: 'metadata.dealDueDate',
  METADATA_DEPOSIT_TYPE: 'metadata.depositType',
  METADATA_DOWN_PAYMENT: 'metadata.downPayment',
  METADATA_PRESENTATION_LINKS: 'metadata.presentationLinks',
  EXHIBIT: 'exhibit',
  CUSTOM_REFERENCE_NUMBER: 'customReferenceNumber',
  IS_NEW: 'isNew',
  DESCRIPTION: 'description',
  TERMS_OF_SALE: 'termsOfSale',
  STREET_ADDRESS: 'streetAddress',
  ZIP_CODE: 'zipCode',
  CITY: 'city.id',
  NET_AUCTION_CLASS_TYPE: 'netAuctionClass.type',
  COMMISSION_OWNER_FIRST_NAME: 'commissionOwner.firstName',
  COMMISSION_OWNER_LAST_NAME: 'commissionOwner.lastName',
  COMMISSION_OWNER_SSN: 'commissionOwner.ssn',
  COMMISSION_OWNER_SSN_HASH: 'commissionOwner.ssnHash',
  COMMISSION_OWNER_COMPANY_NAME: 'commissionOwner.companyName',
  COMMISSION_OWNER_COMPANY_BUSINESS_ID: 'commissionOwner.businessId',
  COMMISSION_OWNER_COMPANY_VAT_NUMBER: 'commissionOwner.vatNumber',
  COMMISSION_OWNER_TYPE: 'commissionOwner.type',
  COMMISSION_OWNER_ADDRESS: 'commissionOwner.address',
  COMMISSION_OWNER_PHONE: 'commissionOwner.phone',
  COMMISSION_OWNER_EMAIL: 'commissionOwner.email',
  IS_RESPONSIBLE_FOR_VALIDITY: 'isResponsibleForValidity',
  IS_OWNERSHIP_VERIFIED: 'isOwnershipVerified',
  IS_MONEY_NOT_TRANSFERRED_BEFORE_VERIFIED_DELIVERY: 'isMoneyNotTransferredBeforeVerifiedDelivery',
  IS_COMMISSION: 'isCommission',
  METADATA_IS_SPECIAL_VEHICLE: 'metadata.isSpecialVehicle',
  DEFECTS: 'defects',
  NO_DEFECTS: 'noDefects',
  METADATA_IN_WORKING_ORDER: 'metadata.inWorkingOrder',
  METADATA_ODOMETER: 'metadata.odometer',
  METADATA_MAINTENANCE_BOOK: 'metadata.maintenanceBook',
  METADATA_MAINTENANCE_BOOK_DESCRIPTION: 'metadata.maintenanceBookDescription',
  METADATA_LICENSED_TWICE: 'metadata.licensedTwice',
  METADATA_LICENSED_TWICE_REASON: 'metadata.licensedTwiceReason',
  METADATA_IS_IMPORTED: 'metadata.isImported',
  METADATA_IMPORTED_FROM: 'metadata.importedFrom',
  METADATA_IS_IMPORT_TAX_PAID: 'metadata.isImportTaxPaid',
  METADATA_IMPORTED_TAX_ESTIMATE: 'metadata.importedTaxEstimate',
  METADATA_PARTS_REPLACED_PERCENT: 'metadata.partsReplacedPercent',
  METADATA_TAXES_OVERDUE: 'metadata.taxesOverdue',
  METADATA_TAXES_OVERDUE_AMOUNT: 'metadata.taxesOverdueAmount',
  METADATA_AMOUNT_OF_KEYS: 'metadata.amountOfKeys',
  METADATA_FAULTY_KEY_REMOTE: 'metadata.faultyKeyRemote',
  METADATA_FAULTY_KEY_REMOTE_DESCRIPTION: 'metadata.faultyKeyRemoteDescription',
  METADATA_FAULTY_WINDSHIELD: 'metadata.faultyWindshield',
  METADATA_FAULTY_WINDSHIELD_DESCRIPTION: 'metadata.faultyWindshieldDescription',
  METADATA_CONTAINS_SUMMER_TIRES: 'metadata.containsSummerTires',
  METADATA_ARE_SUMMER_TIRES_LEGAL: 'metadata.areSummerTiresLegal',
  METADATA_CONTAINS_WINTER_TIRES: 'metadata.containsWinterTires',
  METADATA_ARE_WINTER_TIRES_LEGAL: 'metadata.areWinterTiresLegal',
  METADATA_FAULTY_EXTERIOR: 'metadata.faultyExterior',
  METADATA_FAULTY_EXTERIOR_DESCRIPTION: 'metadata.faultyExteriorDescription',
  METADATA_FAULTY_INTERIOR: 'metadata.faultyInterior',
  METADATA_FAULTY_INTERIOR_DESCRIPTION: 'metadata.faultyInteriorDescription',
  METADATA_WARNING_INDICATOR: 'metadata.warningIndicator',
  METADATA_WARNING_INDICATOR_DESCRIPTION: 'metadata.warningIndicatorDescription',
  METADATA_DELIVERY_CONTAINS_ALL_ITEMS: 'metadata.deliveryContainsAllItems',
  METADATA_DELIVERY_DETAILS: 'metadata.deliveryDetails',
  METADATA_EMISSION_MANIPULATION: 'metadata.emissionManipulation',
  METADATA_EMISSION_MANIPULATION_DESCRIPTION: 'metadata.emissionManipulationDescription',
  METADATA_EMISSION_MANIPULATION_NO_ROAD_PERMIT: 'metadata.emissionManipulationNoRoadPermit',
  CONFIRM_AND_CONSENT: 'confirmAndConsent',
  CONTACTS: 'contacts',
  PUBLISHERS: 'publishers',
  BILLING_ADDRESS: 'billingAddress.id',
  DELIVERY_EXPENSES: 'deliveryExpenses',
  DELIVERY_EXPENSES_UNAVAILABLE: 'deliveryExpensesUnavailable',
  DELIVERY_DESCRIPTION: 'deliveryDescription',
  RETRIEVAL_EXPENSES: 'retrievalExpenses',
  RETRIEVAL_DESCRIPTION: 'retrievalDescription',
  RETRIEVAL_CONTACT_NAME: 'retrievalContactName',
  RETRIEVAL_CONTACT_PHONE: 'retrievalContactPhone',
  DELIVERY_SELECTABLE_METHOD: 'deliverySelectableMethod',
  METADATA_LICENSE_NUMBER: 'metadata.licenseNumber',
  METADATA_NOT_REGISTERED_REASON: 'metadata.notRegisteredReason',
  METADATA_NEXT_INSPECTION: 'metadata.nextInspection',
  METADATA_INSPECTION_FAILED_REASON: 'metadata.inspectionFailReason',
  METADATA_REGISTRATION_STATUS: 'metadata.registrationStatus',
  METADATA_INSPECTION_STATUS: 'metadata.inspectionStatus',
  METADATA_WITHDRAWN_FROM_TRAFFIC: 'metadata.withdrawnFromTraffic',
  METADATA_VIN: 'metadata.vin',
  METADATA_VEHICLE_INSURANCE: 'metadata.vehicleInsurance',
  METADATA_CE_MARKING_STATUS: 'metadata.ceMarkingStatus',
  METADATA_MANUFACTURER: 'metadata.manufacturer',
  METADATA_MODEL: 'metadata.model',
  METADATA_YEAR_MODEL: 'metadata.yearModel',
  METADATA_OPERATIONAL_HOURS: 'metadata.operationalHours',
  METADATA_DRIVE: 'metadata.drive',
  METADATA_COMMISSIONING_DATE: 'metadata.commissioningDate',
  METADATA_FUEL_TYPE: 'metadata.fuelType',
  METADATA_ENGINE_DISPLACEMENT: 'metadata.engineDisplacement',
  METADATA_POWER: 'metadata.power',
  METADATA_POWER_TYPE: 'metadata.powerType',
  METADATA_CATEGORY: 'metadata.category',
  METADATA_BASE_CATEGORY: 'metadata.baseCategory',
  METADATA_TRANSMISSION: 'metadata.transmission',
  METADATA_NO_ROAD_PERMIT: 'metadata.noRoadPermit',
  METADATA_PREVIOUS_LICENSE_NUMBER: 'metadata.previousLicenseNumber',
  METADATA_BREAKS: 'metadata.breaks',
  METADATA_TOTAL_MASS: 'metadata.totalMass',
  BOOK_VALUE: 'bookValue',
  ADMIN_IS_HIDDEN: 'isHiddenFromLists',
  ADMIN_PAGE_ALWAYS_VISIBLE: 'pageAlwaysVisible',
  ADMIN_OWN_PAYMENT_OVERRIDE: 'ownPaymentOverride',
  ADMIN_IS_PRIVATE_AUCTION: 'isPrivateAuction',
  ADMIN_IS_SPECIAL_ENTRY: 'isSpecialEntry',
  ADMIN_IS_VERIFIED_ENTRY: 'isVerifiedEntry',
  ADMIN_PRIVATE_NET_AUCTION_GROUP: 'privateNetAuctionGroup.id',
  ADMIN_PRIVATE_AUCTION_BIDDER_EMAILS: 'privateAuctionBidderIds',
  ADMIN_HAS_CUSTOM_BADGE: 'hasCustomBadge',
  ADMIN_CUSTOM_BADGE: 'customBadge',
  ADMIN_NOTES: 'notes',
  BANKRUPTCY_ESTATE_NAME: 'bankruptcyEstateName',
  NEEDS_PERMIT: 'needsPermit',
  DESIRED_PRICE: 'desiredPrice',
  IMAGE_COUNT: 'imageCount',
  TEMPLATE_NAME: 'templateName',
  IS_PUBLISH_DELAYED: 'isPublishDelayed',
};

export type FieldNames = Record<keyof typeof fieldNames, string>;

const adminFields = [
  fieldNames.ADMIN_IS_HIDDEN,
  fieldNames.ADMIN_PAGE_ALWAYS_VISIBLE,
  fieldNames.ADMIN_OWN_PAYMENT_OVERRIDE,
  fieldNames.ADMIN_IS_PRIVATE_AUCTION,
  fieldNames.ADMIN_PRIVATE_NET_AUCTION_GROUP,
  fieldNames.ADMIN_PRIVATE_AUCTION_BIDDER_EMAILS,
  fieldNames.ADMIN_NOTES,
  fieldNames.ADMIN_IS_SPECIAL_ENTRY,
  fieldNames.ADMIN_IS_VERIFIED_ENTRY,
  fieldNames.ADMIN_HAS_CUSTOM_BADGE,
  fieldNames.ADMIN_CUSTOM_BADGE,
];

function getCategoryDependantFieldHelp(field: string, categories: string[] = []) {
  if (intersection(categories, [metadataCategories.METADATA_PASSENGER_CAR]).length !== 0) {
    const carFieldNames = {
      [fieldNames.NO_DEFECTS]:
        'Ilmoitathan mahdolliset katsastuksen huomautukset (hylkäys tai korjauskehotukset), sekä kaikki tiedossasi olevat viat. Pientenkin virheiden ja vikojen kertominen kertoo ilmoittajan luotettavuudesta, madaltaa kynnystä jättää tarjouksia ja ehkäisee kaupan jälkeisiä riitatilanteita.',
      [fieldNames.DEFECTS]:
        'Ilmoita katsastuksen huomautusten osalta myös, mihin mennessä korjattava (jos ei vielä korjattu). Jos auto ei ole tieliikennekelpoinen, kerro miksi.',
    };

    return carFieldNames[field];
  }

  return null;
}

function getCategoryDependantFieldLabel(field: string, categories: string[] = []) {
  if (intersection(categories, [metadataCategories.METADATA_BOAT]).length !== 0) {
    const boatFieldNames = {
      [fieldNames.METADATA_REGISTRATION_STATUS]: 'Onko vene rekisterissä?',
      [fieldNames.METADATA_INSPECTION_STATUS]: 'Onko vene katsastettu?',
      [fieldNames.METADATA_NOT_REGISTERED_REASON]: 'Kerro miksei vene ole rekisterissä?',
    };

    return boatFieldNames[field];
  }

  if (
    intersection(categories, [
      metadataCategories.METADATA_CONSTRUCTION_MACHINERY,
      metadataCategories.METADATA_FARMING_MACHINERY,
      metadataCategories.METADATA_FOREST_MACHINERY,
      metadataCategories.METADATA_OTHER_MACHINERY,
    ]).length !== 0
  ) {
    const machineryFieldNames = {
      [fieldNames.METADATA_REGISTRATION_STATUS]: 'Onko kone rekisterissä?',
      [fieldNames.METADATA_INSPECTION_STATUS]: 'Onko kone katsastettu?',
      [fieldNames.METADATA_NOT_REGISTERED_REASON]: 'Kerro miksei kone ole rekisterissä?',
      [fieldNames.METADATA_VEHICLE_INSURANCE]: 'Onko koneessa voimassaoleva liikennevakuutus?',
    };

    return machineryFieldNames[field];
  }

  if (intersection(categories, [metadataCategories.METADATA_PASSENGER_CAR]).length !== 0) {
    const carFieldNames = {
      [fieldNames.METADATA_REGISTRATION_STATUS]: 'Onko auto rekisterissä?',
      [fieldNames.METADATA_INSPECTION_STATUS]: 'Onko auto katsastettu?',
      [fieldNames.METADATA_NOT_REGISTERED_REASON]: 'Kerro miksei auto ole rekisterissä?',
      [fieldNames.METADATA_VEHICLE_INSURANCE]: 'Onko autossa voimassa oleva liikennevakuutus?',
      [fieldNames.NO_DEFECTS]: 'Havaitut viat',
    };

    return carFieldNames[field];
  }

  if (intersection(categories, [metadataCategories.METADATA_REGISTRABLE_VEHICLE]).length !== 0) {
    const registrableVehicleFieldNames = {
      [fieldNames.METADATA_REGISTRATION_STATUS]: 'Onko ajoneuvo rekisterissä?',
      [fieldNames.METADATA_INSPECTION_STATUS]: 'Onko ajoneuvo katsastettu?',
      [fieldNames.METADATA_NOT_REGISTERED_REASON]: 'Kerro miksei ajoneuvoa ole rekisterissä?',
      [fieldNames.METADATA_VEHICLE_INSURANCE]: 'Onko ajoneuvossa voimassa oleva liikennevakuutus?',
      [fieldNames.METADATA_WITHDRAWN_FROM_TRAFFIC]: 'Onko ajoneuvo liikennekäytössä?',
    };

    return registrableVehicleFieldNames[field];
  }

  if (intersection(categories, [metadataCategories.METADATA_TRUCK]).length !== 0) {
    const registrableVehicleFieldNames = {
      [fieldNames.METADATA_REGISTRATION_STATUS]: 'Onko kone/ajoneuvo rekisterissä?',
      [fieldNames.METADATA_INSPECTION_STATUS]: 'Onko kone/ajoneuvo katsastettu?',
      [fieldNames.METADATA_NOT_REGISTERED_REASON]: 'Kerro miksei kone/ajoneuvo ole rekisterissä?',
      [fieldNames.METADATA_VEHICLE_INSURANCE]: 'Onko koneessa/ajoneuvossa voimassa oleva liikennevakuutus?',
      [fieldNames.METADATA_WITHDRAWN_FROM_TRAFFIC]: 'Onko ajoneuvo liikennekäytössä?',
    };

    return registrableVehicleFieldNames[field];
  }

  return null;
}

function getVatDependantFieldLabel(fieldName: string, vatPercent: string) {
  const vatType = vatPercent !== null && vatPercent !== '0.00' && vatPercent !== 'MARGINAL_VAT' ? 'with' : 'without';

  const vatLabelNames = {
    [fieldNames.START_PRICE]: {
      without: 'Lähtöhinta',
      with: 'Lähtöhinta sisältäen ALV:n',
    },
    [fieldNames.RESERVE_PRICE]: {
      without: 'Hintavaraus',
      with: 'Hintavaraus sisältäen ALV:n',
    },
    [fieldNames.MIN_BID]: {
      without: 'Minimikorotus',
      with: 'Minimikorotus sisältäen ALV:n',
    },
  };

  if (fieldName in vatLabelNames) {
    return vatLabelNames[fieldName][vatType];
  }

  return null;
}

function getCopyFieldName(field: string) {
  const fieldPath = field.split('.');

  if (fieldPath[0] !== 'copy') {
    return null;
  }

  return fieldPath[fieldPath.length - 1];
}

export default {
  ...fieldNames,
  adminFields,
  getVatDependantFieldLabel,

  getFieldHelp(field: string, categories: string[] = []): string {
    const categoryDependantFieldHelp = getCategoryDependantFieldHelp(field, categories);
    if (categoryDependantFieldHelp) {
      return categoryDependantFieldHelp;
    }

    const fieldLabelMap = {
      [fieldNames.EXHIBIT]: 'Kohteille on tarjottava tutustumismahdollisuus.',
      [fieldNames.METADATA_FAULTY_EXTERIOR_DESCRIPTION]:
        'Kuvaile korissa olevat vauriot mahdollisimman tarkasti. Kerro kulumien ja kolhujen koosta, sijainnista sekä tyypistä.',
      [fieldNames.METADATA_FAULTY_INTERIOR_DESCRIPTION]:
        'Kuvaile yksityiskohtaisesti ajoneuvon sisällä olevia kulumia. Luettele selkeästi kaikki rikkinäiset kohdat ja kulumat.',
      [fieldNames.METADATA_MAINTENANCE_BOOK_DESCRIPTION]:
        'Voit halutessasi lisätä tietoja huoltokirjasta tai viimeisimmistä huolloista.',
      [fieldNames.METADATA_LICENSED_TWICE_REASON]:
        'Esim. kolarihistoria, muutoskatsastettu, erikoiskilvet, diplomaattikilvet.',
      [fieldNames.NO_DEFECTS]:
        'Pientenkin virheiden ja vikojen kertominen kertoo ilmoittajan luotettavuudesta ja ehkäisee kaupan jälkeisiä riitatilanteita.',
      [fieldNames.METADATA_EMISSION_MANIPULATION]:
        'Moottorin ohjelmoinnilla ja päästömanipulaatiolla tarkoitetaan moottorin ohjainlaitteen muutoksia, joilla on vaikutusta moottorin toimintaan ja ominaisuuksiin, esimerkiksi tehon lisäykset ja optimoinnit. Päästömanipulaatio tarkoittaa myös pakokaasupäästöjärjestelmien muutoksia, esimerkiksi DPF, SCR ja EGR, jotka ovat olennaisia pakokaasujärjestelmien osia.',
      [fieldNames.METADATA_EMISSION_MANIPULATION_NO_ROAD_PERMIT]:
        'Kohteessa on havaittu muutoksia moottorin ohjelmoinnissa tai pakokaasujärjestelmissä, minkä vuoksi se ei ole tieliikennekelpoinen.',
    };

    return fieldLabelMap[field] || '';
  },

  getFieldLabel(field: string, categories: string[] = []): string {
    const categoryDependantFieldLabel = getCategoryDependantFieldLabel(field, categories);
    if (categoryDependantFieldLabel) {
      return categoryDependantFieldLabel;
    }

    const fieldLabelMap = {
      [fieldNames.TITLE]: 'Kohteen nimi',
      [fieldNames.SUBTITLE]: 'Lisätiedot',
      [fieldNames.METHOD_OF_SALES]: 'Myyntitapa',
      [fieldNames.RESERVE_PRICE]: 'Hintavaraus',
      [fieldNames.START_PRICE]: 'Lähtöhinta',
      [fieldNames.MIN_BID]: 'Minimikorotus',
      [fieldNames.AUCTION_START]: 'Huutokauppa alkaa',
      [fieldNames.AUCTION_END]: 'Huutokauppa päättyy',
      [fieldNames.AUCTION_DURATION]: 'Huutokaupan kesto',
      [fieldNames.CONTINUOUS_AUCTION]: 'Jatkuva huutokauppa',
      [fieldNames.VAT_PERCENT]: 'ALV %',
      [fieldNames.METADATA_APARTMENT_TYPE]: 'Talotyyppi',
      [fieldNames.METADATA_ROOM_COUNT]: 'Huoneita',
      [fieldNames.METADATA_LIVING_AREA]: 'Asuinpinta-ala',
      [fieldNames.METADATA_CONSTRUCTION_YEAR]: 'Rakennusvuosi',
      [fieldNames.METADATA_MAINTENANCE_FEE]: 'Hoitovastike',
      [fieldNames.METADATA_LOAN_PAYMENT]: 'Rahoitusvastike',
      [fieldNames.METADATA_MANAGEMENT_CHARGE]: 'Yhtiövastike',
      [fieldNames.METADATA_DEBT]: 'Velkaosuus',
      [fieldNames.METADATA_DEAL_DUE_DATE]: 'Kaupat viimeistään',
      [fieldNames.METADATA_DEPOSIT_TYPE]: 'Käsiraha',
      [fieldNames.METADATA_DOWN_PAYMENT]: 'Käsirahan summa',
      [fieldNames.EXHIBIT]: 'Kohteeseen tutustuminen (esim. ajankohta ja yhteyshenkilö)',
      [fieldNames.CUSTOM_REFERENCE_NUMBER]: 'Ilmoittajan viite',
      [fieldNames.IS_NEW]: 'Kohde on',
      [fieldNames.DESCRIPTION]: 'Ilmoitusteksti',
      [fieldNames.TERMS_OF_SALE]: 'Myyntiehdot',
      [fieldNames.STREET_ADDRESS]: 'Katuosoite',
      [fieldNames.ZIP_CODE]: 'Postinumero',
      [fieldNames.CITY]: 'Valitse kunta / kaupunki',
      [fieldNames.DESIRED_PRICE]: 'Tavoitehinta',
      [fieldNames.COMMISSION_OWNER_FIRST_NAME]: 'Etunimi',
      [fieldNames.COMMISSION_OWNER_LAST_NAME]: 'Sukunimi',
      [fieldNames.COMMISSION_OWNER_SSN]: 'Henkilötunnus',
      [fieldNames.COMMISSION_OWNER_COMPANY_NAME]: 'Yrityksen nimi',
      [fieldNames.COMMISSION_OWNER_COMPANY_BUSINESS_ID]: 'Y-tunnus',
      [fieldNames.COMMISSION_OWNER_COMPANY_VAT_NUMBER]: 'ALV-tunnus',
      [fieldNames.COMMISSION_OWNER_TYPE]: 'Toimeksiantaja on',
      [fieldNames.COMMISSION_OWNER_ADDRESS]: 'Osoite',
      [fieldNames.COMMISSION_OWNER_PHONE]: 'Puhelinnumero',
      [fieldNames.COMMISSION_OWNER_EMAIL]: 'Sähköpostiosoite',
      [fieldNames.IS_RESPONSIBLE_FOR_VALIDITY]:
        'Ymmärrän, että ilmoittajana olen vastuussa kohteen tietojen oikeellisuudesta.',
      [fieldNames.IS_OWNERSHIP_VERIFIED]: 'Olen varmistanut toimeksiantajan omistusoikeuden kohteeseen.',
      [fieldNames.IS_MONEY_NOT_TRANSFERRED_BEFORE_VERIFIED_DELIVERY]:
        'En missään tapauksessa tilitä kauppasummaa toimeksiantajalle ennen kohteen todennettua luovutusta ostajalle. Näin estän olemattoman tavaran myynnin josta ilmoittajana olen vastuussa.',
      [fieldNames.IS_COMMISSION]: 'Edustamani yritys ei ole myytävän kohteen omistaja',
      [fieldNames.METADATA_IS_SPECIAL_VEHICLE]:
        'Onko ajoneuvo rekisteröity sairaankuljetus-, poliisi-, pelastus-, ruumis- tai eläinlääkintäajoneuvoksi?',
      [fieldNames.DEFECTS]: 'Havaitut viat',
      [fieldNames.NO_DEFECTS]: 'Onko kohteessa havaittuja vikoja?',
      [fieldNames.METADATA_WITHDRAWN_FROM_TRAFFIC]: 'Onko ajoneuvo liikennekäytössä?',
      [fieldNames.METADATA_IN_WORKING_ORDER]: 'Onko kone työkuntoinen?',
      [fieldNames.METADATA_ODOMETER]: 'Mittarilukema',
      [fieldNames.METADATA_MAINTENANCE_BOOK]: 'Onko huoltokirja mukana?',
      [fieldNames.METADATA_MAINTENANCE_BOOK_DESCRIPTION]: 'Lisätietoja huoltokirjasta',
      [fieldNames.METADATA_LICENSED_TWICE]: 'Onko auto rekisteröity uudelleen?',
      [fieldNames.METADATA_LICENSED_TWICE_REASON]: 'Miksi auto on rekisteröity uudelleen?',
      [fieldNames.METADATA_PREVIOUS_LICENSE_NUMBER]: 'Edellinen rekisteritunnus',
      [fieldNames.METADATA_IS_IMPORTED]: 'Onko auto tuontiauto?',
      [fieldNames.METADATA_IMPORTED_FROM]: 'Tuontimaa',
      [fieldNames.METADATA_IS_IMPORT_TAX_PAID]: 'Onko autovero maksettu?',
      [fieldNames.METADATA_IMPORTED_TAX_ESTIMATE]: 'Arvio autoveron määrästä?',
      [fieldNames.METADATA_TAXES_OVERDUE]: 'Onko autosta ajoneuvoveroja maksamatta?',
      [fieldNames.METADATA_TAXES_OVERDUE_AMOUNT]: 'Kuinka paljon veroja on maksamatta?',
      [fieldNames.METADATA_AMOUNT_OF_KEYS]: 'Avainten lukumäärä?',
      [fieldNames.METADATA_FAULTY_KEY_REMOTE]: 'Toimiiko avaimien kaukosäätimet?',
      [fieldNames.METADATA_FAULTY_KEY_REMOTE_DESCRIPTION]: 'Kuvaile vika',
      [fieldNames.METADATA_FAULTY_WINDSHIELD]: 'Onko tuulilasissa halkeamia tai kiveniskemiä?',
      [fieldNames.METADATA_FAULTY_WINDSHIELD_DESCRIPTION]: 'Kuvaile vika',
      [fieldNames.METADATA_CONTAINS_SUMMER_TIRES]: 'Kuuluvatko kesärenkaat kauppaan?',
      [fieldNames.METADATA_ARE_SUMMER_TIRES_LEGAL]: 'Ovatko kesärenkaiden kulutuspinnat lailliset?',
      [fieldNames.METADATA_CONTAINS_WINTER_TIRES]: 'Kuuluvatko talvirenkaat kauppaan?',
      [fieldNames.METADATA_ARE_WINTER_TIRES_LEGAL]: 'Ovatko talvirenkaiden kulutuspinnat lailliset?',
      [fieldNames.METADATA_FAULTY_EXTERIOR]: 'Onko auton korissa/maalipinnassa kulumia, ruostetta tai kolhuja?',
      [fieldNames.METADATA_FAULTY_EXTERIOR_DESCRIPTION]: 'Kuvaile korin vauriot',
      [fieldNames.METADATA_FAULTY_INTERIOR]: 'Onko auton sisällä kulumia tai jotain rikki?',
      [fieldNames.METADATA_FAULTY_INTERIOR_DESCRIPTION]: 'Kerro mahdollisimman tarkasti mitä on rikki',
      [fieldNames.METADATA_WARNING_INDICATOR]: 'Palaako jokin vikavalo?',
      [fieldNames.METADATA_WARNING_INDICATOR_DESCRIPTION]: 'Kuvaile mikä vikavalo palaa',
      [fieldNames.METADATA_DELIVERY_CONTAINS_ALL_ITEMS]:
        'Onko autossa luovutushetkellä kaikki kauppaan kuuluva (asiapaperit, renkaat jne)?',
      [fieldNames.METADATA_DELIVERY_DETAILS]: 'Kuvaile mitä puuttuu ja miten toimitetaan',
      [fieldNames.CONTACTS]: 'Kuka on ilmoituksessa julkisesti näkyvä yhteyshenkilö?',
      [fieldNames.PUBLISHERS]: 'Kenelle lähetetään tieto kohteen päättymisestä, maksusta ja muista tapahtumista?',
      [fieldNames.BILLING_ADDRESS]: 'Laskutusosoite',
      [fieldNames.DELIVERY_EXPENSES]: 'Toimitus- ja pakkauskulut sisältäen ALV:n',
      [fieldNames.DELIVERY_EXPENSES_UNAVAILABLE]: 'Toimituskulut ovat',
      [fieldNames.DELIVERY_DESCRIPTION]: 'Toimitustapa ja toimitusaika',
      [fieldNames.RETRIEVAL_EXPENSES]: 'Noutokulu sisältäen ALV:n',
      [fieldNames.RETRIEVAL_DESCRIPTION]: 'Ohjeet noutajalle',
      [fieldNames.RETRIEVAL_CONTACT_NAME]: 'Nimi',
      [fieldNames.RETRIEVAL_CONTACT_PHONE]: 'Puhelinnumero',
      [fieldNames.DELIVERY_SELECTABLE_METHOD]: 'Toimitustapa',
      [fieldNames.METADATA_LICENSE_NUMBER]: 'Rekisteritunnus',
      [fieldNames.METADATA_NEXT_INSPECTION]: 'Seuraava katsastuskuukausi',
      [fieldNames.METADATA_INSPECTION_FAILED_REASON]: 'Kuvaile mitä puutteita tai miksi ei ole katsastettu',
      [fieldNames.METADATA_VIN]: 'Valmiste-/runkonumero',
      [fieldNames.METADATA_CE_MARKING_STATUS]: 'Onko kone CE-merkitty?',
      [fieldNames.METADATA_MANUFACTURER]: 'Merkki',
      [fieldNames.METADATA_MODEL]: 'Malli',
      [fieldNames.METADATA_YEAR_MODEL]: 'Vuosimalli',
      [fieldNames.METADATA_OPERATIONAL_HOURS]: 'Mittarilukema',
      [fieldNames.METADATA_DRIVE]: 'Vetotapa',
      [fieldNames.METADATA_COMMISSIONING_DATE]: 'Käyttöönottokuukausi',
      [fieldNames.METADATA_FUEL_TYPE]: 'Käyttövoima',
      [fieldNames.METADATA_ENGINE_DISPLACEMENT]: 'Moottorin tilavuus',
      [fieldNames.METADATA_POWER]: 'Teho',
      [fieldNames.METADATA_POWER_TYPE]: 'Tehon tyyppi',
      [fieldNames.METADATA_TRANSMISSION]: 'Vaihteisto',
      [fieldNames.METADATA_NO_ROAD_PERMIT]: 'Auto ei ole tieliikennekelpoinen',
      [fieldNames.BOOK_VALUE]: 'Kirjanpitoarvo',
      [fieldNames.ADMIN_IS_HIDDEN]: 'Piilota kohde',
      [fieldNames.ADMIN_PAGE_ALWAYS_VISIBLE]: 'Jätä kohde näkyviin sivulle',
      [fieldNames.ADMIN_OWN_PAYMENT_OVERRIDE]: 'Käyttäjien välinen kauppa',
      [fieldNames.ADMIN_IS_PRIVATE_AUCTION]: 'Suljettu huutokauppa',
      [fieldNames.ADMIN_IS_SPECIAL_ENTRY]: 'Erikoiskohde',
      [fieldNames.ADMIN_IS_VERIFIED_ENTRY]: 'Tarkastettu kohde',
      [fieldNames.ADMIN_PRIVATE_NET_AUCTION_GROUP]: 'Kohde kuuluu suljettuun huutokauppatapahtumaan',
      [fieldNames.ADMIN_PRIVATE_AUCTION_BIDDER_EMAILS]:
        'Yksityisen huutokaupan osallistujat (käyttäjien sähköpostiosoitteet pilkulla eroteltuna)',
      [fieldNames.ADMIN_HAS_CUSTOM_BADGE]: 'Kustomoitu tunniste',
      [fieldNames.ADMIN_CUSTOM_BADGE]: 'Kohteella on kustomoitu tunniste',
      [fieldNames.ADMIN_NOTES]: 'Sisäiset muistiinpanot',
      [fieldNames.BANKRUPTCY_ESTATE_NAME]: 'Konkurssipesän nimi',
      [fieldNames.NEEDS_PERMIT]: 'Vaatiiko tuotteen myyminen, ostaminen tai asentaminen luvan?',
      [fieldNames.IMAGE_COUNT]: 'Lisää kuvia',
      [fieldNames.METADATA_BREAKS]: 'Jarrut',
      [fieldNames.METADATA_TOTAL_MASS]: 'Kokonaismassa',
      [fieldNames.METADATA_EMISSION_MANIPULATION]:
        'Onko ajoneuvoon tehty muutoksia moottorin ohjelmointiin tai pakokaasujärjestelmiin?',
      [fieldNames.METADATA_EMISSION_MANIPULATION_DESCRIPTION]: 'Tarkenna mitä muutoksia ajoneuvoon on tehty',
      [fieldNames.CONFIRM_AND_CONSENT]:
        'Vakuutan ilmoituksella antamani tiedot oikeiksi, ja vahvistan tutustuneeni ilmoittajaehtoihin, käyttöehtoihin, kohteiden sääntöihin, ilmoittamisen sääntöihin, myyntiehtoihin, peruutusten sääntöihin sekä hyväksyväni kyseiset ehdot ja säännöt',
      [fieldNames.TEMPLATE_NAME]: 'Ilmoituspohjan nimi',
    };

    return fieldLabelMap[getCopyFieldName(field) ?? field] || '';
  },

  getAuctionStartFieldName(copyIndex?: number): string {
    if (copyIndex === undefined) {
      return fieldNames.AUCTION_START;
    }

    return `copy.${copyIndex}.${fieldNames.AUCTION_START}`;
  },

  getAuctionEndFieldName(copyIndex?: number): string {
    if (copyIndex === undefined) {
      return fieldNames.AUCTION_END;
    }

    return `copy.${copyIndex}.${fieldNames.AUCTION_END}`;
  },

  getAuctionDurationFieldName(copyIndex?: number): string {
    if (copyIndex === undefined) {
      return fieldNames.AUCTION_DURATION;
    }

    return `copy.${copyIndex}.${fieldNames.AUCTION_DURATION}`;
  },
};
