import createFetchClient, { type Middleware } from 'openapi-fetch';
import createClient from 'openapi-react-query';
import { paths } from '@typings/schema';
import { useToast } from '@mezzoforte/forge';
import { useMemo } from 'react';

export class ClientError extends Error {
  readonly response: Response;

  constructor(response: Response) {
    super('ClientError');
    this.response = response;
  }
}

export const useApi = () => {
  const { playToast } = useToast();

  return useMemo(() => {
    const headers = {
      retries: 0,
      'Cache-Control': 'no-cache',
    };

    const onErrorMiddleware: Middleware = {
      async onResponse({ response }) {
        if (!response.ok) {
          if (response.status === 503) {
            playToast(
              'Pieni hetki, huollamme sivustoa',
              'Huutokaupat.com on pois käytöstä huoltotöiden takia. Pyrimme saamaan palvelun käyttöön mahdollisimman pian.',
              {
                toastId: 'service-unavailable-error',
                variant: 'danger',
                closeManually: true,
              }
            );
          }

          if (response.status === 429) {
            playToast('Virhe', 'Liikaa pyyntöjä. Odota hetki ja yritä sen jälkeen uudestaan.', {
              toastId: 'too-many-requests-error',
              variant: 'danger',
              closeManually: true,
            });
          }

          throw new ClientError(response);
        }
      },
    };

    const client = createFetchClient<paths>({ headers });
    client.use(onErrorMiddleware);

    return createClient(client);
  }, [playToast]);
};
