import React from 'react';
import { Box } from '@mezzoforte/forge';
import { useSession } from '../hooks/useSession';
import { useCompanySelection } from '@hooks/company-selection/use-company-selection';
import { CompanyRow } from '@app/src/header/UserGreeting/CompanyRow';
import { CompanySelector } from '@app/src/header/UserGreeting/CompanySelector';
import styled from '@emotion/styled';
import { useShouldShowCompanySelector } from '@app/src/header/hooks/useShouldShowCompanySelector';

export const UserGreeting = () => {
  const { currentUser } = useSession();
  const { selectedCompany } = useCompanySelection();
  const { shouldShowCompanySelector } = useShouldShowCompanySelector();

  if (!shouldShowCompanySelector) {
    return (
      <Box
        as="span"
        color="subdued"
        display="block"
        px={3}
        py={2}
      >
        {currentUser.data?.user?.firstName ? `Hei, ${currentUser.data?.user?.firstName}` : 'Hei!'}
      </Box>
    );
  }

  return (
    <UserGreetingBox pt={2}>
      <Box
        borderBottomWidth="1px"
        borderBottomStyle="solid"
        borderColor="lineBorder"
      >
        <Box
          as="span"
          display="block"
          px={3}
        >
          <Box
            color="subdued"
            pb={2}
          >
            Olet kirjautunut käyttäjänä
          </Box>
          {selectedCompany && <CompanyRow company={selectedCompany} />}
        </Box>
      </Box>
      <Box
        borderBottomWidth="1px"
        borderBottomStyle="solid"
        borderColor="lineBorder"
        mb={2}
      >
        <CompanySelector />
      </Box>
    </UserGreetingBox>
  );
};

const UserGreetingBox = styled(Box)`
  color: black;
  display: block;
`;
