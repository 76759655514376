import React, { useState } from 'react';
import { Box, Button, PasswordInput, TextInput } from '@mezzoforte/forge';

export interface LoginFormValues {
  readonly username: string;
  readonly password: string;
}

export interface LoginFormProps {
  readonly onSubmit: (values: LoginFormValues) => Promise<void>;
  readonly isError: boolean;
}

export const LoginForm = React.forwardRef<HTMLInputElement, LoginFormProps>(({ onSubmit, isError }, ref) => {
  // ToDo: Once LoginProvider login is refactored to use react-query we can get rid of this
  // internal pending state.
  const [isPending, setPending] = useState(false);

  const submitLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    setPending(true);
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- ensure type
    const values = Object.fromEntries(formData.entries()) as unknown as LoginFormValues;
    await onSubmit(values);
    setPending(false);
  };

  return (
    <Box
      as="form"
      onSubmit={submitLogin}
      data-test="hk-login-form"
    >
      <TextInput
        ref={ref}
        data-test="hk-username-field"
        type="email"
        name="username"
        label="Sähköpostiosoite"
        autoComplete="username"
        autoFocus
        isRequired
        isDisabled={isPending}
        isInvalid={isError}
      />
      <Box mt={3}>
        <PasswordInput
          data-test="hk-password-field"
          name="password"
          label="Salasana"
          autoComplete="current-password"
          isRequired
          isDisabled={isPending}
          isInvalid={isError}
        />
      </Box>
      <Button
        mt={4}
        type="submit"
        variant="highlight-hero"
        width="100%"
        isLoading={isPending}
        data-test="hk-login-submit"
      >
        Kirjaudu
      </Button>
    </Box>
  );
});
