import { useApi } from '@hooks/use-api';

export const useSession = () => {
  const api = useApi();

  const userQuery = api.useQuery('get', '/api/users/current-user', { staleTime: 1000 * 60 });

  return {
    currentUser: userQuery,
  };
};
