import React from 'react';
import { SliceComponentProps } from '@prismicio/react';
import type { Content } from '@prismicio/client';
import { NavItem, NavSubItem } from '../../MainNavigationItem';
import { asLink } from '@prismicio/helpers';
import { resolve } from '../../util/url';
import CategoryMenu from '../../Category/CategoryMenu';
import type { MainNavigationIconNames } from '../../util/icons';
import { splitBy } from '../../util/array';
import { linkResolver } from '../../prismicio';
import { useSession } from '../../hooks/useSession';
import { useLogin } from '@components/login/LoginProvider';
import { components } from '@typings/schema';

type Flatten<T> = T extends any[] ? T[number] : T;
type SubMenuItem = Content.MainNavigationItemSliceDropdownItem | Content.MainNavigationItemSliceUserMenuItem;

function userRoles(
  user?: components['schemas']['RegisteredUser']
): Flatten<Content.MainNavigationItemSliceDropdownItem['display_for']>[] {
  if (!user) return ['Everyone', 'Unauthenticated'];
  const roleStrings = ['Everyone', 'Authenticated', 'Buyer', 'Seller', 'Accounting', 'Admin'] as const;
  const conditions = [true, true, user.isBuyer, user.isSeller, user.isAccounting, user.isAdmin];
  return roleStrings.filter((_, i) => conditions[i]);
}

function filterForVisitor(item: SubMenuItem, user?: components['schemas']['RegisteredUser']) {
  const roles = userRoles(user);
  if (roles.includes(item.hide_for)) return false; // explicit disallow list
  if (!roles.includes(item.display_for)) return false; // explicit allow list
  return true;
}

export default function MainNavigationItem({ slice }: SliceComponentProps<Content.MainNavigationItemSlice>) {
  const { requireLogin } = useLogin();

  function menuItemsFromSlice(slice: Content.MainNavigationItemSlice) {
    return splitBy<SubMenuItem>(item => item.type === 'Spacer', slice.items).map(
      list =>
        list
          .filter(item => filterForVisitor(item, currentUser.data?.user))
          .map<NavSubItem | undefined>(item => {
            switch (item.type) {
              case 'UserGreeting':
                return {
                  type: 'UserGreeting',
                  label: 'Hei!',
                };
              case 'Link':
                return {
                  type: 'Link',
                  label: item.title ?? '',
                  href: resolve(asLink(item.link, linkResolver) ?? '#'),
                };
              case 'Text':
                return { type: 'Text', label: item.title ?? '' };
              default:
                return undefined;
            }
          })
          .filter(item => item !== undefined) as NavSubItem[]
    );
  }

  const { currentUser } = useSession();
  const loggedIn = currentUser.data?.isAuthenticated ?? false;

  const props = {
    label: slice.primary.title ?? '',
    icon: (slice.primary.icon as MainNavigationIconNames) ?? ('List' as MainNavigationIconNames),
  };

  switch (slice.variation) {
    case 'default':
      return (
        <NavItem
          type="Link"
          href={resolve(asLink(slice.primary.link, linkResolver) ?? '#')}
          {...props}
        />
      );
    case 'userMenu':
      return (
        <NavItem
          {...props}
          type={loggedIn ? 'Dropdown' : 'Button'}
          label={loggedIn ? props.label : slice.primary.title_for_unauthenticated ?? 'Kirjaudu'}
          items={menuItemsFromSlice(slice)}
          displayLabel={!currentUser.isLoading}
          onClick={loggedIn ? () => {} : requireLogin}
        />
      );
    case 'dropdown':
      return (
        <NavItem
          type="Dropdown"
          items={menuItemsFromSlice(slice)}
          {...props}
        />
      );
    case 'categoryMenu':
      return (
        <NavItem
          type="DropdownFullwidth"
          customMenu={<CategoryMenu />}
          {...props}
        />
      );
  }

  return null;
}
