// @flow

import { List, Map } from 'immutable';
import contactMessageService from '../src/net-auction/contact-message-service';
import netAuctionService from '../src/net-auction/net-auction-service';

const OPEN_CONTACT_MESSAGE_MODAL = 'huutokaupat/contact-message/OPEN_CONTACT_MESSAGE_MODAL';
const CLOSE_CONTACT_MESSAGE_MODAL = 'huutokaupat/contact-message/CLOSE_CONTACT_MESSAGE_MODAL';

const SUBMIT_MESSAGE = 'huutokaupat/contact-message/SUBMIT_MESSAGE';
const SUBMIT_MESSAGE_DONE = 'huutokaupat/contact-message/SUBMIT_MESSAGE_DONE';
const SUBMIT_MESSAGE_FAILED = 'huutokaupat/contact-message/SUBMIT_MESSAGE_FAILED';

const LOAD_CONTACT_MESSAGES = 'huutokaupat/net-auction/entry/contact-messages/LOAD_CONTACT_MESSAGES';
const LOAD_CONTACT_MESSAGES_DONE = 'huutokaupat/net-auction/entry/contact-messages/LOAD_CONTACT_MESSAGES_DONE';
const LOAD_CONTACT_MESSAGES_FAILED = 'huutokaupat/net-auction/entry/contact-messages/LOAD_CONTACT_MESSAGES_FAILED';

const LOG_CONTACT_MESSAGE_STATISTICS_FAILED =
  'huutokaupat/net-auction/entry/contact-messages/LOG_CONTACT_MESSAGE_STATISTICS_FAILED';

const initialState = Map({
  company: null,
  contactMessages: List(),
  email: null,
  entry: null,
  entryId: null,
  open: false,
  submittingContactMessage: false,
  contactMessageSubmissionFailed: false,
  loadingContactMessages: false,
  loadingContactMessagesFailed: false,
});

export default (state: Map<string, any> = initialState, action: Object = {}): Object => {
  switch (action.type) {
    case OPEN_CONTACT_MESSAGE_MODAL:
      return state
        .set('entryId', action.payload.entryId)
        .set('companyName', action.payload.companyName)
        .set('contacts', action.payload.contacts)
        .set('open', true);

    case CLOSE_CONTACT_MESSAGE_MODAL:
      return state.set('entryId', null).set('companyName', null).set('contacts', null).set('open', false);

    case SUBMIT_MESSAGE:
      return state
        .set('open', false)
        .set('submittingContactMessage', true)
        .set('contactMessageSubmissionFailed', false);

    case SUBMIT_MESSAGE_DONE:
      return state.set('submittingContactMessage', false);

    case SUBMIT_MESSAGE_FAILED:
      return state.set('submittingContactMessage', false).set('contactMessageSubmissionFailed', true);

    case LOAD_CONTACT_MESSAGES:
      return state.set('loadingContactMessages', true).set('loadingContactMessagesFailed', false);

    case LOAD_CONTACT_MESSAGES_DONE:
      return state
        .set('loadingContactMessages', false)
        .set('company', action.payload.company)
        .set('entry', action.payload.entry || null)
        .set('contactMessages', List(action.payload.contactMessages));

    case LOAD_CONTACT_MESSAGES_FAILED:
      return state.set('loadingContactMessages', false).set('loadingContactMessagesFailed', true);
    default:
      return state;
  }
};

export function openContactMessageModal(entryId: number, companyName: string): Object {
  return (dispatch: Function): Object =>
    netAuctionService.incrementContactStats(entryId, 'message').then(
      response =>
        dispatch({
          type: OPEN_CONTACT_MESSAGE_MODAL,
          payload: { entryId, companyName, contacts: response.contacts },
        }),
      () => dispatch({ type: LOG_CONTACT_MESSAGE_STATISTICS_FAILED })
    );
}

export function closeContactMessageModal(): Object {
  return { type: CLOSE_CONTACT_MESSAGE_MODAL };
}

export function submitContactMessage(entryId: number, contactUuid: string, message: string): Object {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: SUBMIT_MESSAGE });

    return contactMessageService.saveContactMessage(entryId, contactUuid, message).then(
      result => dispatch({ type: SUBMIT_MESSAGE_DONE, payload: result }),
      error => dispatch({ type: SUBMIT_MESSAGE_FAILED, payload: error })
    );
  };
}

export function loadContactMessages(companyId: number, entryId: ?number): Object {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: LOAD_CONTACT_MESSAGES });

    return contactMessageService.getContactMessages(companyId, entryId).then(
      result => dispatch({ type: LOAD_CONTACT_MESSAGES_DONE, payload: result }),
      error => dispatch({ type: LOAD_CONTACT_MESSAGES_FAILED, payload: error })
    );
  };
}
