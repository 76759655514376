import React from 'react';
import { Link } from '../link/Link';
import { customerServiceContactLink } from '../../../utils';

export const CustomerServiceDetails = () => (
  <>
    Asiakaspalvelun yhteystiedot ja aukioloajat löytyvät{' '}
    <Link
      linkVariant="primary"
      to={customerServiceContactLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      täältä.
    </Link>
  </>
);
