import React, { ReactNode, useRef, useState, createContext } from 'react';
import styled from '@emotion/styled';
import { Box, Flex, useTokens } from '@mezzoforte/forge';
import { SubMenu } from './MainNavigationSubMenu';
import { useClickOutside } from './useClickOutside';
import { type MainNavigationIconNames, NavigationIcon } from './util/icons';
import { CompanyIcon } from '@app/src/header/UserGreeting/CompanyIcon';
import { useShouldShowCompanySelector } from '@app/src/header/hooks/useShouldShowCompanySelector';
import { useCompanySelection } from '@hooks/company-selection/use-company-selection';

const { colors } = useTokens.huutokaupat;
interface NavItemBase {
  icon: MainNavigationIconNames;
  label: string;
  type: 'Link' | 'Dropdown' | 'DropdownFullwidth' | 'Button';
  displayLabel?: boolean;
  className?: string;
}

export interface NavItemLink extends NavItemBase {
  type: 'Link';
  href: string;
}

export interface NavItemDropdown extends NavItemBase {
  type: 'Dropdown';
  customMenu?: ReactNode;
  items?: NavSubItem[][];
}

export interface NavItemDropdownFullwidth extends NavItemBase {
  type: 'DropdownFullwidth';
  customMenu?: ReactNode;
  items?: NavSubItem[][];
}

export interface NavItemButton extends NavItemBase {
  type: 'Button';
  onClick: () => void;
}

export type NavItemProps = NavItemButton | NavItemLink | NavItemDropdown | NavItemDropdownFullwidth;

interface NavSubItemBase {
  label: string;
  type: 'Link' | 'Text' | 'UserGreeting';
}

export interface NavSubItemLink extends NavSubItemBase {
  type: 'Link';
  href: string;
}

export interface NavSubItemText extends NavSubItemBase {
  type: 'Text';
}

export interface NavSubItemUserGreeting extends NavSubItemBase {
  type: 'UserGreeting';
}

export type NavSubItem = NavSubItemLink | NavSubItemText | NavSubItemUserGreeting;

export const NavItemContext = createContext({ close: () => {} });

export const NavItem: React.FC<NavItemProps> = item => {
  const { shouldShowCompanySelector } = useShouldShowCompanySelector();
  const { selectedCompany } = useCompanySelection();

  const [isActive, setIsActive] = useState(false);
  const element = useRef<HTMLDivElement & HTMLLIElement>(null);
  const hasSubmenu = item.type === 'Dropdown' || item.type === 'DropdownFullwidth';

  const hideSubmenu = () => setIsActive(false);
  useClickOutside(element, hideSubmenu);

  const onClick: React.MouseEventHandler<HTMLAnchorElement> = event => {
    if (item.type === 'Link') return;
    event.preventDefault();
    setIsActive(val => !val);
    if (item.type === 'Button') item.onClick();
  };
  const ForgeIcon = NavigationIcon(item.icon);
  // OK to use Link when no navigation is intended: https://www.w3.org/WAI/tutorials/menus/flyout/#use-parent-as-toggle

  // destruct props not supported by the Box from item
  const { displayLabel, customMenu, ...restOfItem } = { ...item, customMenu: hasSubmenu ? item.customMenu : undefined };
  return (
    <NavItemContext.Provider value={{ close: hideSubmenu }}>
      <Box
        ref={element}
        as="li"
        key={item.label}
        listStyleType="none"
        position={{
          base: 'static',
          lg: item.type === 'DropdownFullwidth' ? 'static' : 'relative',
        }}
        data-test="main-nav-item"
        lineHeight="unset"
        margin="unset"
        {...restOfItem}
      >
        <Link
          href={item.type === 'Link' ? item.href : '#'}
          aria-expanded={hasSubmenu ? isActive : undefined}
          onClick={onClick}
        >
          <Flex
            flexDir="column"
            alignItems="center"
          >
            {item.icon === 'UserCircle' && selectedCompany && shouldShowCompanySelector ? (
              <CompanyIcon company={selectedCompany} />
            ) : (
              <ForgeIcon size="24px" />
            )}
            <Box
              as="span"
              fontSize={{ base: 12, lg: 14 }}
              lineHeight={{ base: '19px', lg: '18px' }}
              visibility={displayLabel === false ? 'hidden' : 'visible'}
            >
              {item.label}
            </Box>
          </Flex>
        </Link>
        {hasSubmenu && (
          <SubMenu
            display={isActive}
            item={item}
          />
        )}
      </Box>
    </NavItemContext.Provider>
  );
};

const Link = styled.a`
  text-decoration: none;

  span + span {
    border-bottom: 1px solid transparent;
  }

  &[aria-expanded='true'] span + span {
    border-bottom-color: white;
  }

  color: ${colors.textInvert};
  :hover,
  :active,
  :visited,
  :focus {
    color: ${colors.textInvert};
    text-decoration: none;
  }
`;
