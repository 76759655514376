import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { reduxForm, Field } from 'redux-form';
import accounting from 'accounting';
import { Row, Col, Nav, NavItem, Form, FormGroup, InputGroup, FormControl, Button, Checkbox } from 'react-bootstrap';
import cx from 'classnames';
import { Box, Link, Tabs, TabList, Tab } from '@mezzoforte/forge';
import netAuctionService from '../../../src/net-auction/net-auction-service';
import CompanySelect from '../../admin/util/CompanySelect';
import CompanyNavBar from '../../company/CompanyNavBar';
import AdvancedSearchModal from './modal/AdvancedSearchModal';
import { SellerType } from '../../../src/net-auction/seller-type';

export const listTypes = [
  { label: 'Käynnissä', value: 'ongoing' },
  { label: 'Päättyneet', value: 'ended' },
  { label: 'Hintaneuvottelut', value: 'post-trading' },
  { label: 'Hyväksytyt', value: 'approved' },
  { label: 'Toimitettavat', value: 'deliverable' },
  { label: 'Luovutettavat', value: 'handover' },
  { label: 'Tulossa', value: 'unpublished' },
  { label: 'Luonnokset', value: 'draft' },
  { label: 'Ilmoituspohjat', value: 'template' },
];

class NetAuctionListFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      previousWindowWidth: window.outerWidth,
      isAdvancedSearchModalOpen: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      if (window.outerWidth === this.state.previousWindowWidth) {
        return;
      }

      const { type, change } = this.props;

      if (window.outerWidth < 768 && type === 'dashboard') {
        change('type', 'ongoing');
      }

      // Force a rerender on resize.
      this.setState({ previousWindowWidth: window.outerWidth });
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { change } = nextProps;

    if (this.props.type !== nextProps.type) {
      change('type', nextProps.type);
    }
  }

  clearCompanyField = () => {
    const { handleSubmit, change } = this.props;

    change('company', '');
    setTimeout(handleSubmit, 0);
  };

  clearTermField = () => {
    const { handleSubmit, change, type } = this.props;

    change('term', '');
    if (type === 'archive') {
      change('type', 'ongoing');
    }

    setTimeout(handleSubmit, 0);
  };

  changeIncludeOldField = () => {
    const { handleSubmit, change, includeOld } = this.props;

    change('includeOld', includeOld ? null : true);
    setTimeout(handleSubmit, 0);
  };

  renderTermField = ({ input, label }) => (
    <FormControl
      type="text"
      minLength={3}
      maxLength={160}
      placeholder={label}
      {...input}
    />
  );

  renderCheckboxField = ({ label }) => (
    <FormGroup>
      <Checkbox
        defaultChecked={this.props.includeOld}
        onChange={this.changeIncludeOldField}
      >
        <span>{label}</span>
      </Checkbox>
    </FormGroup>
  );

  renderTypeField = ({ input, onFieldChange }) => {
    const { term, sectionCounts } = this.props;

    return (
      <Nav
        bsStyle="tabs"
        activeKey={input.value}
        onSelect={e => {
          const ret = input.onChange(e);
          onFieldChange();

          return ret;
        }}
      >
        <NavItem
          eventKey="dashboard"
          className={netAuctionService.isDashboardHidden() ? 'hidden' : 'is-visible'}
        >
          <strong>Kaikki</strong>
          <small>Omat kohteet</small>
        </NavItem>
        {listTypes.map(type => (
          <NavItem
            key={type.value}
            className="is-visible"
            eventKey={type.value}
            disabled={
              term !== null && term.length > 0 && sectionCounts !== undefined && sectionCounts.get(type.value) === 0
            }
          >
            <strong>{type.label}</strong>
            <small>
              {sectionCounts !== undefined && sectionCounts.get(type.value) > 0
                ? `${accounting.formatNumber(sectionCounts.get(type.value), 0, ' ', ',')} kpl`
                : '-'}
            </small>
          </NavItem>
        ))}
        <NavItem
          className="is-visible"
          eventKey="archive"
        >
          <strong>Arkisto</strong>
          <small>
            {sectionCounts !== undefined && sectionCounts.get('archive') > 0
              ? `${accounting.formatNumber(sectionCounts.get('archive'), 0, ' ', ',')} kpl`
              : 'x kpl'}
          </small>
        </NavItem>
      </Nav>
    );
  };

  renderSellerTypeField = ({ input, onFieldChange, fetchCompaniesForSelect }) => {
    return (
      <Tabs
        index={input.value === SellerType.Consumers ? 1 : 0}
        onChange={value => {
          const newValue = value === 0 ? SellerType.Companies : SellerType.Consumers;
          input.onChange(newValue);

          fetchCompaniesForSelect(newValue);

          this.clearCompanyField();
          onFieldChange();
        }}
        marginBottom={5}
      >
        <TabList>
          <Tab>Yritykset</Tab>
          <Tab>Yksityishenkilöt</Tab>
        </TabList>
      </Tabs>
    );
  };

  renderCompanyField = ({ input, onFieldChange, sellerType }) => {
    const { companies } = this.props;

    return (
      <CompanySelect
        placeholder={sellerType === SellerType.Companies ? 'Yrityksen nimi' : 'Ilmoittajan nimi'}
        companies={companies.toJS()}
        {...input}
        onChange={e => {
          const ret = input.onChange(e);
          onFieldChange();

          return ret;
        }}
      />
    );
  };

  render() {
    const { isAdmin, handleSubmit, company, companies, term, includeOld, change, fetchCompaniesForSelect, sellerType } =
      this.props;
    const selectedCompany = companies.find(currentCompany => currentCompany.id === company);

    return (
      <div className="net-auction-list-filter-container">
        {this.state.isAdvancedSearchModalOpen && (
          <AdvancedSearchModal
            initialValues={{ ...netAuctionService.parseAdminListTerm(term), includeOld }}
            company={selectedCompany}
            onClose={() => this.setState({ isAdvancedSearchModalOpen: false })}
            onSubmit={fields => {
              change('includeOld', fields.includeOld ? true : null);

              const fieldsClone = { ...fields };
              delete fieldsClone.includeOld;

              change('term', netAuctionService.createAdminListTerm(fieldsClone));
              this.setState({ isAdvancedSearchModalOpen: false });
              setTimeout(handleSubmit, 0);
            }}
          />
        )}

        {isAdmin && (
          <Field
            name="sellerType"
            onFieldChange={() => setTimeout(handleSubmit, 0)}
            fetchCompaniesForSelect={fetchCompaniesForSelect}
            component={this.renderSellerTypeField}
          />
        )}

        <Row className="search-row">
          <Col
            sm={6}
            md={7}
          >
            {isAdmin ? (
              <FormGroup
                controlId="company"
                className="clearable-input"
              >
                <Field
                  name="company"
                  onFieldChange={() => setTimeout(handleSubmit, 0)}
                  component={this.renderCompanyField}
                  sellerType={sellerType}
                />
                {company !== null && (
                  <button
                    type="button"
                    className="clearable-input__clear-button"
                    onClick={this.clearCompanyField}
                  >
                    <span className="fa fa-times" />
                  </button>
                )}
              </FormGroup>
            ) : (
              <h1>
                Omat huutokaupat
                {term !== null && term.length > 0 ? ` haulla "${term}"` : ''}
              </h1>
            )}
            <small>
              {term !== null && term.length > 0
                ? 'Oletuksena näytetään vain aktiiviset ja viimeisen kuuden kuukauden aikana päättyneet kohteet.'
                : 'Oletuksena näytetään vain aktiiviset ja viimeisen kuuden kuukauden aikana päättyneet kohteet. Valitse Arkisto nähdäksesi\n' +
                  ' arkistossa olevat kohteet ja niiden lukumäärän.'}
            </small>
          </Col>
          <Col
            sm={6}
            md={5}
          >
            <Form onSubmit={handleSubmit}>
              <FormGroup
                controlId="term"
                style={{ marginBottom: 5 }}
              >
                <InputGroup className="clearable-input">
                  <Field
                    name="term"
                    label="Hae kohteen nimellä tai kohdenumerolla"
                    component={this.renderTermField}
                  />
                  {term !== null && term.length > 0 && (
                    <button
                      type="button"
                      className="clearable-input__clear-button more-right-space"
                      onClick={this.clearTermField}
                    >
                      <span className="fa fa-times" />
                    </button>
                  )}
                  <span className="input-group-btn">
                    <Button
                      type="submit"
                      bsStyle="success"
                    >
                      Hae
                    </Button>
                  </span>
                </InputGroup>
              </FormGroup>

              <FormGroup className="pull-left">
                <Field
                  name="includeOld"
                  label="Hae myös vanhoista kohteista"
                  component={this.renderCheckboxField}
                />
              </FormGroup>

              <Box
                as={Link}
                float="right"
                fontSize="xs"
                _hover={{ textDecoration: 'none' }}
                onClick={e => {
                  e.preventDefault();
                  this.setState({ isAdvancedSearchModalOpen: true });
                }}
              >
                Tarkennettu haku
              </Box>
            </Form>
          </Col>
        </Row>

        {selectedCompany && (
          <CompanyNavBar
            company={selectedCompany}
            activePage="net-auctions"
          />
        )}

        <div
          className={cx({
            hidden:
              isAdmin &&
              company === null &&
              sellerType === SellerType.Companies &&
              (term === null || term.length === 0),
          })}
        >
          <Field
            name="type"
            onFieldChange={() => setTimeout(handleSubmit, 0)}
            component={this.renderTypeField}
          />
        </div>
      </div>
    );
  }
}

NetAuctionListFilter.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  company: PropTypes.number,
  term: PropTypes.string,
  type: PropTypes.string,
  includeOld: PropTypes.bool,
  sellerType: PropTypes.number,
  change: PropTypes.func,
  companies: ImmutablePropTypes.list.isRequired,
  sectionCounts: ImmutablePropTypes.map,
  fetchCompaniesForSelect: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'netAuctionListFilter',
})(NetAuctionListFilter);
