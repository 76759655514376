import { AllowedVehicleType } from '@typings/ConsumerNetAuctionForm';
import { List } from 'immutable';
import createClient from '../api/client';
import * as categories from '../net-auction/categories';
// @ts-ignore TODO: Typescript manufacturer-service
import manufacturerService from '../net-auction/manufacturer-service';

export interface Crawler {
  readonly name: string;
  readonly source: string;
}

const crawlers: Record<string, Crawler> = {
  nettiauto: {
    name: 'Nettiauto',
    source: 'nettiauto',
  },
  nettikone: {
    name: 'Nettikone',
    source: 'nettikone',
  },
  nettimoto: {
    name: 'Nettimoto',
    source: 'nettimoto',
  },
  nettivene: {
    name: 'Nettivene',
    source: 'nettivene',
  },
  nettikaravaani: {
    name: 'Nettikaravaani',
    source: 'nettikaravaani',
  },
  nettivaraosa: {
    name: 'Nettivaraosa',
    source: 'nettivaraosa',
  },
  mascus: {
    name: 'Mascus',
    source: 'mascus',
  },
  hankkija: {
    name: 'Hankkija',
    source: 'hankkija',
  },
  etuovi: {
    name: 'Etuovi',
    source: 'etuovi',
  },
  oikotie: {
    name: 'Oikotie',
    source: 'oikotie',
  },
  huom: {
    name: 'Huom!',
    source: 'huom',
  },
  remax: {
    name: 'RE/MAX',
    source: 'remax',
  },
  opkoti: {
    name: 'OP Koti',
    source: 'opkoti',
  },
  trafi: {
    name: 'Trafi',
    source: 'trafi',
  },
  kauppalehti: {
    name: 'Kauppalehti',
    source: 'kauppalehti',
  },
  licenseNumber: {
    name: 'Rekisteritunnus',
    source: 'nettiauto',
  },
};

function getCrawlersForCategory(categoryId: number, isConsumerAuction: boolean = false): Crawler[] {
  switch (categoryId) {
    case categories.CATEGORY_TRUCK:
      return [crawlers.nettiauto, crawlers.nettikone, crawlers.mascus];
    case categories.CATEGORY_HEAVY_EQUIPMENT:
      return [crawlers.nettikone, crawlers.mascus];
    case categories.CATEGORY_CONSTRUCTION_MACHINERY:
    case categories.CATEGORY_FARMING_MACHINERY:
    case categories.CATEGORY_FOREST_MACHINERY:
    case categories.CATEGORY_OTHER_MACHINERY:
      return [crawlers.nettikone, crawlers.mascus, crawlers.hankkija];
    case categories.CATEGORY_PASSENGER_CAR:
    case categories.CATEGORY_VAN:
      return [crawlers.nettiauto];
    case categories.CATEGORY_BOAT:
      return [crawlers.nettivene];
    case categories.CATEGORY_MOTORCYCLE:
    case categories.CATEGORY_SNOWMOBILE:
      return [crawlers.nettimoto];
    case categories.CATEGORY_CARAVAN_AND_TRAILER:
      return isConsumerAuction ? [crawlers.nettivaraosa] : [crawlers.nettikaravaani, crawlers.nettivaraosa];
    case categories.CATEGORY_OTHER_VEHICLE:
      return [crawlers.nettikaravaani];
    case categories.CATEGORY_APARTMENT:
    case categories.CATEGORY_PLOT:
      return [crawlers.etuovi, crawlers.huom, crawlers.remax, crawlers.opkoti]; // Oikotie is disabled for now. See AclTrait::isAllowedToUseOikotieCrawler().
    case categories.CATEGORY_PREMISES:
      return [crawlers.kauppalehti, crawlers.huom, crawlers.remax, crawlers.opkoti];
    case categories.CATEGORY_TIMESHARE:
      return [crawlers.etuovi]; // Oikotie is disabled for now. See AclTrait::isAllowedToUseOikotieCrawler().
    default:
      return [];
  }
}

// Different values returned from the crawlers, ex. not all return title
interface CrawlDataResponse {
  readonly [property: string]: unknown;
  readonly source: string;
  readonly fields?: Record<string, unknown>;
  readonly text?: string;
  readonly title?: string;
  readonly images?: {
    readonly path: string;
    readonly hash: string;
  }[];
}

export const getAvailableCrawlers = async (companyId: number) =>
  createClient()
    .get<{ readonly crawlers: string[] }>(`/crawl/available/${companyId}`)
    .then(response => response.data);

const crawlData = async (
  id: string,
  source: string,
  category: number | null,
  parentCategory?: number | null,
  draftId?: number | null,
  companyId?: number | null
) =>
  createClient()
    .get<CrawlDataResponse>(`/crawl/${source}/${id}`, {
      params: { category, parentCategory, draftId, companyId },
    })
    .then(response => response.data);

const crawlDataWithLicenseNumber = async (
  licenseNumber: string,
  source?: string | null,
  draftId?: number | null,
  companyId?: number | null,
  vehicleType?: AllowedVehicleType
) => {
  const client = createClient();
  const params = { params: { draftId, companyId } };
  if (!vehicleType || vehicleType === 'car') {
    // By specs: Crawl first for Autotalli and then, if it fails, Nettiauto
    const autotalliResponse = await client.get(`/crawl/autotalli/license-number/${licenseNumber}`, params);
    if (!autotalliResponse.data.success) {
      const nettiautoResponse = await client.get(`/crawl/nettiauto/license-number/${licenseNumber}`, params);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- Api responses are not yet typed
      return nettiautoResponse.data;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- Api responses are not yet typed
    return autotalliResponse.data;
  }
  if (vehicleType === 'truck') {
    const nettikoneResponse = await client.get(`/crawl/nettikone/license-number/${licenseNumber}`, params);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- Api responses are not yet typed
    return nettikoneResponse.data;
  }

  return false;
};

const getEnabledCrawlersByCategory = (
  categoryId: number,
  allowedCrawlers: string[],
  isConsumerAuction: boolean = false
): Crawler[] => {
  const crawlersForCategory = getCrawlersForCategory(categoryId, isConsumerAuction);

  return crawlersForCategory.filter(crawler => allowedCrawlers.includes(crawler.source));
};

const getFormFieldForCrawledDataField = (field: string): string => 'metadata'.concat('.', field);

const getManufacturerFromCrawledManufacturer = (crawledManufacturer: string, categoryId: number) => {
  const irregularNames: Record<string, string> = {
    'Tesla motors': 'Tesla',
  };

  if (irregularNames[crawledManufacturer]) {
    return irregularNames[crawledManufacturer];
  }

  const manufacturers = List<string>(manufacturerService.getManufacturersByCategoryId(categoryId));
  return manufacturers.find(
    manufacturer => manufacturer.toLowerCase() === crawledManufacturer.toLowerCase().split(',')[0]
  );
};

export default {
  crawlers,
  crawlData,
  crawlDataWithLicenseNumber,
  getEnabledCrawlersByCategory,
  getFormFieldForCrawledDataField,
  getManufacturerFromCrawledManufacturer,
};
