import { Dayjs } from 'dayjs';
import createClient from '../api/client';

export enum PHASES {
  TypeSelection = 0,
  SearchTool = 1,
  FileUpload = 2,
}

export interface MessageFormData {
  readonly recipients?: string[];
  readonly importedEntries?: boolean;
  readonly allUsers: boolean;
  readonly buyers: boolean;
  readonly sellersWithoutForeclosure: boolean;
  readonly foreclosure: boolean;
  readonly title?: string;
  readonly content?: string;
  readonly publish?: Dayjs;
}

export default {
  createMessage: async (data: MessageFormData) =>
    createClient()
      .post('/admin-messages', data)
      .then(response => response),
};
