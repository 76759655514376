import { Dispatch } from 'redux';
import companySummaryService, {
  CompanyFrontPageEntries,
  FreshdeskTickets,
  CompanySummary,
  CompanyUsers,
} from '../src/company/company-summary-service';

const GetCompanySummary = 'huutokaupat/company-summary/GET_COMPANY_SUMMARY';
const GetCompanySummaryFailed = 'huutokaupat/company-summary/GET_COMPANY_SUMMARY_FAILED';
const GetCompanySummarySuccess = 'huutokaupat/company-summary/GET_COMPANY_SUMMARY_SUCCESS';

const RefetchCompanySummary = 'huutokaupat/company-summary/REFETCH_COMPANY_SUMMARY';
const RefetchCompanySummaryFailed = 'huutokaupat/company-summary/REFETCH_COMPANY_SUMMARY_FAILED';
const RefetchCompanySummarySuccess = 'huutokaupat/company-summary/REFETCH_COMPANY_SUMMARY_SUCCESS';

const GetCompanyUsers = 'huutokaupat/company-summary/GET_COMPANY_USERS';
const GetCompanyUsersFailed = 'huutokaupat/company-summary/GET_COMPANY_USERS_FAILED';
const GetCompanyUsersSuccess = 'huutokaupat/company-summary/GET_COMPANY_USERS_SUCCESS';

const UpdateCompanyNotes = 'huutokaupat/company-summary/UPDATE_COMPANY_NOTES';
const UpdateCompanyNotesFailed = 'huutokaupat/company-summary/UPDATE_COMPANY_NOTES_FAILED';
const UpdateCompanyNotesSuccess = 'huutokaupat/company-summary/UPDATE_COMPANY_NOTES_SUCCESS';

const GetFreshdeskTickets = 'huutokaupat/company-summary/GET_FRESHDESK_TICKETS';
const GetFreshdeskTicketsSuccess = 'huutokaupat/company-summary/GET_FRESHDESK_TICKETS_SUCCESS';
const GetFreshdeskTicketsFailed = 'huutokaupat/company-summary/GET_FRESHDESK_TICKETS_FAILED';

const GetCompanyFrontPageEntries = 'huutokaupat/company-summary/GET_COMPANY_FRONT_PAGE_ENTRIES';
const GetCompanyFrontPageEntriesSuccess = 'huutokaupat/company-summary/GET_COMPANY_FRONT_PAGE_ENTRIES_SUCCESS';
const GetCompanyFrontPageEntriesFailed = 'huutokaupat/company-summary/GET_COMPANY_FRONT_PAGE_ENTRIES_FAILED';

type CompanySummaryReducerAction =
  | GetCompanySummaryAction
  | GetCompanySummarySuccessAction
  | GetCompanySummaryFailedAction
  | RefetchCompanySummaryAction
  | RefetchCompanySummarySuccessAction
  | RefetchCompanySummaryFailedAction
  | GetCompanyFrontPageEntriesAction
  | GetCompanyFrontPageEntriesSuccessAction
  | GetCompanyFrontPageEntriesFailedAction
  | GetCompanyFreshdeskTicketsAction
  | GetCompanyFreshdeskTicketsSuccessAction
  | GetCompanyFreshdeskTicketsFailedAction
  | UpdateCompanyNotesAction
  | UpdateCompanyNotesSuccessAction
  | UpdateCompanyNotesFailedAction
  | GetCompanyUsersAction
  | GetCompanyUsersSuccessAction
  | GetCompanyUsersFailedAction;

export interface CompanySummaryReducerState
  extends Optional<
    CompanyFrontPageEntries & CompanySummary & FreshdeskTickets & CompanyUsers,
    'company' | 'limitedCompany' | 'netAuctionCounts' | 'totalNetAuctionCount' | 'companyApproval'
  > {
  readonly isLoadingSummary: boolean;
  readonly isReloadingSummary: boolean;
  readonly hasUpdateCompanyNotesSuccess: boolean;
  readonly hasUpdateCompanyNotesError: boolean;
  readonly isLoadingTickets: boolean;
  readonly isLoadingUsers: boolean;
}

const initialState: CompanySummaryReducerState = {
  isLoadingSummary: false,
  isReloadingSummary: false,
  company: undefined,
  limitedCompany: undefined,
  netAuctionCounts: undefined,
  totalNetAuctionCount: 0,
  users: [],
  fullUserCount: 0,
  isLoadingUsers: false,
  companyApproval: undefined,
  reclamationCount: 0,
  monitoringNotificationCount: 0,
  frontPageEntries: [],
  hasUpdateCompanyNotesSuccess: false,
  hasUpdateCompanyNotesError: false,
  isLoadingTickets: false,
  freshdeskTickets: [],
  partialAnnulmentCount: 0,
  fullAnnulmentCount: 0,
  type: '',
  internalDisplayName: '',
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last -- Reducer.
  state: CompanySummaryReducerState = initialState,
  action: CompanySummaryReducerAction
): CompanySummaryReducerState => {
  switch (action.type) {
    case GetCompanySummary:
      return {
        ...state,
        company: initialState.company,
        users: initialState.users,
        netAuctionCounts: initialState.netAuctionCounts,
        totalNetAuctionCount: initialState.totalNetAuctionCount,
        companyApproval: initialState.companyApproval,
        reclamationCount: initialState.reclamationCount,
        monitoringNotificationCount: initialState.monitoringNotificationCount,
        isLoadingSummary: true,
      };

    case GetCompanySummaryFailed:
      return {
        ...state,
        isLoadingSummary: false,
      };

    case GetCompanySummarySuccess:
      return {
        ...state,
        ...action.payload.data,
        isLoadingSummary: false,
      };

    case RefetchCompanySummary:
      return {
        ...state,
        isReloadingSummary: true,
      };

    case RefetchCompanySummaryFailed:
      return {
        ...state,
        isReloadingSummary: false,
      };

    case RefetchCompanySummarySuccess:
      return {
        ...state,
        ...action.payload.data,
        isReloadingSummary: false,
      };

    case UpdateCompanyNotes:
      return {
        ...state,
        hasUpdateCompanyNotesSuccess: false,
        hasUpdateCompanyNotesError: false,
      };

    case UpdateCompanyNotesFailed:
      return {
        ...state,
        hasUpdateCompanyNotesError: true,
        hasUpdateCompanyNotesSuccess: false,
      };

    case UpdateCompanyNotesSuccess:
      return {
        ...state,
        hasUpdateCompanyNotesSuccess: true,
        hasUpdateCompanyNotesError: false,
      };

    case GetFreshdeskTickets:
      return {
        ...state,
        freshdeskTickets: initialState.freshdeskTickets,
        isLoadingTickets: true,
      };

    case GetFreshdeskTicketsSuccess:
      return {
        ...state,
        ...action.payload.data,
        isLoadingTickets: false,
      };

    case GetFreshdeskTicketsFailed:
      return {
        ...state,
        isLoadingTickets: false,
      };

    case GetCompanyFrontPageEntries:
      return {
        ...state,
        company: initialState.company,
        frontPageEntries: initialState.frontPageEntries,
        isLoadingSummary: true,
      };

    case GetCompanyFrontPageEntriesSuccess:
      return {
        ...state,
        ...action.payload.data,
        isLoadingSummary: false,
      };

    case GetCompanyFrontPageEntriesFailed:
      return {
        ...state,
        isLoadingSummary: false,
      };

    case GetCompanyUsers:
      return {
        ...state,
        isLoadingUsers: true,
      };

    case GetCompanyUsersSuccess:
      return {
        ...state,
        ...action.payload.data,
        isLoadingUsers: false,
      };

    case GetCompanyUsersFailed:
      return {
        ...state,
        isLoadingUsers: false,
      };

    default:
      return { ...state };
  }
};

interface GetCompanySummaryAction {
  readonly type: typeof GetCompanySummary;
}

interface GetCompanySummarySuccessAction {
  readonly type: typeof GetCompanySummarySuccess;
  readonly payload: {
    readonly data: CompanySummary;
  };
}

interface GetCompanySummaryFailedAction {
  readonly type: typeof GetCompanySummaryFailed;
}

interface RefetchCompanySummaryAction {
  readonly type: typeof RefetchCompanySummary;
}

interface RefetchCompanySummarySuccessAction {
  readonly type: typeof RefetchCompanySummarySuccess;
  readonly payload: {
    readonly data: CompanySummary;
  };
}

interface RefetchCompanySummaryFailedAction {
  readonly type: typeof RefetchCompanySummaryFailed;
}

export type GetCompanySummaryFunction = Action<typeof getCompanySummary>;

export function getCompanySummary(companyId: number) {
  return (dispatch: Dispatch) => {
    dispatch({ type: GetCompanySummary });

    companySummaryService.getCompanySummary(companyId).then(
      data => dispatch({ type: GetCompanySummarySuccess, payload: { data } }),
      () => dispatch({ type: GetCompanySummaryFailed })
    );
  };
}

export function refetchCompanySummary(companyId: number) {
  return (dispatch: Dispatch) => {
    dispatch({ type: RefetchCompanySummary });

    companySummaryService.getCompanySummary(companyId).then(
      data => dispatch({ type: RefetchCompanySummarySuccess, payload: { data } }),
      () => dispatch({ type: RefetchCompanySummaryFailed })
    );
  };
}

interface GetCompanyUsersAction {
  readonly type: typeof GetCompanyUsers;
}

interface GetCompanyUsersSuccessAction {
  readonly type: typeof GetCompanyUsersSuccess;
  readonly payload: {
    readonly data: CompanyUsers;
  };
}

interface GetCompanyUsersFailedAction {
  readonly type: typeof GetCompanyUsersFailed;
}

export type GetCompanyUsersFunction = Action<typeof getCompanyUsers>;

export function getCompanyUsers(companyId: number, limit?: number) {
  return (dispatch: Dispatch) => {
    dispatch({ type: GetCompanyUsers });

    companySummaryService.getCompanyUsers(companyId, limit).then(
      data => dispatch({ type: GetCompanyUsersSuccess, payload: { data } }),
      () => dispatch({ type: GetCompanyUsersFailed })
    );
  };
}

interface GetCompanyFrontPageEntriesAction {
  readonly type: typeof GetCompanyFrontPageEntries;
}

interface GetCompanyFrontPageEntriesSuccessAction {
  readonly type: typeof GetCompanyFrontPageEntriesSuccess;
  readonly payload: {
    readonly data: CompanyFrontPageEntries;
  };
}

interface GetCompanyFrontPageEntriesFailedAction {
  readonly type: typeof GetCompanyFrontPageEntriesFailed;
}

export type GetCompanyFrontPageEntriesFunction = Action<typeof getCompanyFrontPageEntries>;

export function getCompanyFrontPageEntries(companyId: number) {
  return (dispatch: Dispatch) => {
    dispatch({ type: GetCompanyFrontPageEntries });

    companySummaryService.getCompanyFrontPageEntries(companyId).then(
      data =>
        dispatch({
          type: GetCompanyFrontPageEntriesSuccess,
          payload: { data },
        }),
      error =>
        dispatch({
          type: GetCompanyFrontPageEntriesFailed,
          payload: error,
        })
    );
  };
}

interface GetCompanyFreshdeskTicketsAction {
  readonly type: typeof GetFreshdeskTickets;
}

interface GetCompanyFreshdeskTicketsSuccessAction {
  readonly type: typeof GetFreshdeskTicketsSuccess;
  readonly payload: {
    readonly data: FreshdeskTickets;
  };
}

interface GetCompanyFreshdeskTicketsFailedAction {
  readonly type: typeof GetFreshdeskTicketsFailed;
}

export type GetFreshdeskTicketsFunction = Action<typeof getFreshdeskTickets>;

export function getFreshdeskTickets(companyId: number) {
  return (dispatch: Dispatch) => {
    dispatch({ type: GetFreshdeskTickets });

    companySummaryService.getFreshdeskTickets(companyId).then(
      data => dispatch({ type: GetFreshdeskTicketsSuccess, payload: { data } }),
      error => dispatch({ type: GetFreshdeskTicketsFailed, payload: error })
    );
  };
}

interface UpdateCompanyNotesAction {
  readonly type: typeof UpdateCompanyNotes;
}

interface UpdateCompanyNotesSuccessAction {
  readonly type: typeof UpdateCompanyNotesSuccess;
}

interface UpdateCompanyNotesFailedAction {
  readonly type: typeof UpdateCompanyNotesFailed;
}

export type UpdateCompanyNotesFunction = Action<typeof updateCompanyNotes>;

export function updateCompanyNotes(companyId: number, notes: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: UpdateCompanyNotes });

    companySummaryService.updateCompanyNotes(companyId, notes).then(
      response =>
        dispatch({
          type: UpdateCompanyNotesSuccess,
          payload: response.success,
        }),
      error => dispatch({ type: UpdateCompanyNotesFailed, payload: error })
    );
  };
}
