import { NetAuctionClass } from '@typings/NetAuctionClass';
import { Category } from '@typings/Category';
import { CommissionOwner } from '@typings/CommissionOwner';
import { ConsumerNetAuctionDraftStatus } from '@typings/ConsumerNetAuctionDraftStatus';
import { City } from '@typings/City';
import { FileResource } from '@typings/FileResource';
import { User } from '@app/src/header/types/User';

export enum NetAuctionDraftType {
  Draft = 1,
  Template = 2,
  ImportedEntry = 3,
}

interface NetAuctionDraftCommon {
  readonly id: number;
  readonly city: City | null;
  readonly netAuctionClass: NetAuctionClass;
  readonly category: Category;
  readonly title: string | null;
  readonly subtitle: string | null;
  readonly streetAddress: string | null;
  readonly zipCode: string | null;
  readonly exhibit: string | null;
  readonly methodOfSales: 1 | 2 | 3 | null;
  readonly minBid: string | null;
  readonly description: string | null;
  readonly termsOfSale: string | null;
  readonly defects: string | null;
  readonly noDefects: boolean | null;
  readonly retrievalContactName: string | null;
  readonly retrievalContactPhone: string | null;
  readonly metadata: Record<string, string | number | boolean> | null;
  readonly auctionStart: string | null;
  readonly auctionEnd: string | null;
  readonly auctionDuration: number | null;
  readonly commissionOwner: CommissionOwner | null;
  readonly uuid: string | null;
  readonly desiredPrice: number | null;
  readonly confirmAndConsent: boolean;
}

interface NetAuctionDraftSellerAdminAccountingCommon {
  readonly company: unknown;
  readonly startPrice: string | null;
  readonly billingAddress: unknown | null;
  readonly bankruptcyEstateName: string | null;
  readonly reservePrice: string | null;
  readonly vatPercent: string | null;
  readonly customReferenceNumber: string | null;
  readonly contacts: unknown[];
  readonly publishers: unknown[];
  readonly deliverySelectableMethod: boolean | null;
  readonly deliveryExpenses: string | null;
  readonly deliveryDescription: string | null;
  readonly retrievalExpenses: string | null;
  readonly deliveryExpensesUnavailable: boolean | null;
  readonly continuousAuction: boolean | null;
  readonly isNew: boolean | null;
  readonly shouldUseAuctionDuration: boolean;
  readonly isCommission: boolean | null;
  readonly isMoneyNotTransferredBeforeVerifiedDelivery: boolean | null;
  readonly isOwnershipVerified: boolean | null;
  readonly isResponsibleForValidity: boolean | null;
  readonly bookValue: string | null;
  readonly needsPermit: string | null;
  readonly ownPaymentOverride: boolean;
  readonly isPrivateAuction: boolean;
}

interface NetAuctionDraftAdminAccountingCommon {
  readonly createdBy: User;
  readonly updatedBy: unknown;
  readonly isHiddenFromLists: string | null;
  readonly pageAlwaysVisible: boolean | null;
  readonly notes: string | null;
  readonly privateAuctionBidderIds: unknown[];
  readonly usedTemplateIds: unknown[];
  readonly copiedFromEntry: unknown | null;
  readonly createdEntry: unknown | null;
  readonly privateNetAuctionGroup: unknown | null;
  readonly isVerifiedEntry: boolean;
  readonly isSpecialEntry: boolean;
}

interface NetAuctionDraftAdminConsumerCommon {
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly consumerNetAuctionDraftStatus: ConsumerNetAuctionDraftStatus | null;
  readonly isLegacyConsumerDraft: boolean;
  readonly isPublishDelayed: boolean;
}

export interface AdminNetAuctionDraft
  extends NetAuctionDraftCommon,
    NetAuctionDraftSellerAdminAccountingCommon,
    NetAuctionDraftAdminAccountingCommon,
    NetAuctionDraftAdminConsumerCommon {}

export interface SellerNetAuctionDraft extends NetAuctionDraftCommon, NetAuctionDraftSellerAdminAccountingCommon {}

export interface AccountingNetAuctionDraft
  extends NetAuctionDraftCommon,
    NetAuctionDraftSellerAdminAccountingCommon,
    NetAuctionDraftAdminAccountingCommon {}

export interface ConsumerNetAuctionDraft extends NetAuctionDraftCommon, NetAuctionDraftAdminConsumerCommon {
  readonly priceEstimate: number | null;
  readonly crawlingId?: string;
  readonly mainPhoto: FileResource | null;
}
