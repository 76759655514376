// @flow

import { List, Map } from 'immutable';

const FETCH_REGIONS = 'huutokaupat/region/FETCH_REGIONS';
const FETCH_REGIONS_DONE = 'huutokaupat/region/FETCH_REGIONS_DONE';
const FETCH_REGIONS_FAILED = 'huutokaupat/region/FETCH_REGIONS_FAILED';

const initialState = Map({
  loading: true,
  failed: false,
  regions: List(),
});

export default (state: Map<string, any> = initialState, action: Object = {}): Object => {
  switch (action.type) {
    case FETCH_REGIONS:
      return state.set('loading', true).set('failed', false);

    case FETCH_REGIONS_DONE:
      return state.set('loading', false).set('failed', false).set('regions', List(action.payload));

    case FETCH_REGIONS_FAILED:
      return state.set('loading', false).set('failed', true);

    default:
      return state;
  }
};
