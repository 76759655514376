// @flow

import React from 'react';
import { ControlLabel } from 'react-bootstrap';
import cx from 'classnames';
import { Toggletip } from '@mezzoforte/forge';

type Props = {
  input: Object,
  label: string,
  toolTip: ?string,
  disabled: ?boolean,
};

/** @deprecated use Forge components + react-hook-form */
export default function CheckboxField({ input, label, toolTip, disabled }: Props): React$Node {
  return (
    <ControlLabel className={cx('no-margin', { disabled })}>
      <input
        type="checkbox"
        checked={!!input.value}
        disabled={disabled}
        {...input}
      />
      {label}
      {toolTip && (
        <span>
          <Toggletip>{toolTip}</Toggletip>
        </span>
      )}
    </ControlLabel>
  );
}
