// @flow

import React from 'react';
import { Box, Heading, useTokens } from '@mezzoforte/forge';
import { Link } from '@components/link/Link';
import cx from 'classnames';
import { Row, Col, Button, ButtonGroup, MenuItem, DropdownButton } from 'react-bootstrap';
import { getStatusStringAndColor } from '../../src/company/company-status-service';
import { COMPANY_TYPES } from '../../src/company/company-service';

const { colors } = useTokens.huutokaupat;

function CompanyNavBarHeader({ company, useStatusColors }) {
  const statusColor = useStatusColors ? getCompanyTextColor(company) : colors.text;
  return (
    <Heading
      variant="h2"
      as="h3"
      className="company-title"
      color={statusColor}
    >
      {company.name} {company.type === COMPANY_TYPES.KULUTTAJA ? `(${company.internalDisplayName})` : ''}
      <Box
        as="small"
        ml={2}
      >
        {company && company.tikonIds && 'Tikon ID '.concat(company.tikonIds.join(', '))}
      </Box>
      <Button
        bsSize="xs"
        href={`/yllapito/tapahtumaloki/company/${company.id}`}
      >
        Tapahtumaloki
      </Button>
    </Heading>
  );
}

type Props = {
  company: Object,
  activePage?: string,
  useStatusColors?: boolean,
};

function getCompanyTextColor(company): string {
  return company.isUserEnabled ? getStatusStringAndColor(company.status).color : colors.text;
}

export default function CompanyNavBar({ company, activePage, useStatusColors = true }: Props): React$Node {
  if (!company) {
    return null;
  }

  return (
    <div>
      <Row className="company-header hidden-md hidden-lg">
        <Col sm={12}>
          <CompanyNavBarHeader
            company={company}
            useStatusColors={useStatusColors}
          />
        </Col>
        <Col
          className="button-dropdown hidden-md hidden-lg"
          xs={6}
        >
          <DropdownButton
            title="Valikot"
            bsSize="large"
            id="company-navbar-dropdown"
          >
            <MenuItem href={`/yllapito/yritykset/${company.id}`}>Yhteenveto</MenuItem>
            <MenuItem href={`/hallinta/nettihuutokaupat?company=${company.id}`}>Huutokaupat</MenuItem>
            <MenuItem href={`/yllapito/yritykset/${company.id}/raportointi`}>Raportit</MenuItem>
            <MenuItem href={`/yllapito/yritykset/${company.id}/tiedustelut`}>Tiedustelut</MenuItem>
            <MenuItem href={`/yllapito/palautteet?company=${company.id}`}>Palautteet</MenuItem>
            <MenuItem href={`/yllapito/yritykset/${company.id}/etusivulla`}>Etusivulla</MenuItem>
          </DropdownButton>
        </Col>
        <Col
          className="edit-company-button-container"
          xs={6}
        >
          <Link
            className="btn btn-primary"
            to={`/yllapito/yritykset/${company.id}/yleiset`}
          >
            {' '}
            Muokkaa
          </Link>
        </Col>
      </Row>

      <Row className="company-header hidden-xs hidden-sm">
        <Col md={10}>
          <CompanyNavBarHeader
            company={company}
            useStatusColors={useStatusColors}
          />
        </Col>
        <Col
          className="edit-company-button-container"
          md={2}
        >
          <Link
            className="btn btn-primary"
            to={`/yllapito/yritykset/${company.id}/yleiset`}
          >
            {' '}
            Muokkaa
          </Link>
        </Col>
        <Col
          className="hidden-xs hidden-sm"
          md={12}
        >
          <ButtonGroup
            justified
            bsSize="large"
            className="button-toolbar"
          >
            <Link
              className={cx('btn btn-default', {
                disabled: activePage === 'summary',
              })}
              to={`/yllapito/yritykset/${company.id}`}
            >
              Yhteenveto
            </Link>
            <Link
              className={cx('btn btn-default', {
                disabled: activePage === 'net-auctions',
              })}
              to={`/hallinta/nettihuutokaupat?company=${company.id}`}
            >
              Huutokaupat
            </Link>
            <Link
              to={`/yllapito/yritykset/${company.id}/raportointi`}
              className={cx('btn btn-default', {
                disabled: activePage === 'reports',
              })}
            >
              Raportit
            </Link>
            <Link
              className={cx('btn btn-default', {
                disabled: activePage === 'contact-messages',
              })}
              to={`/yllapito/yritykset/${company.id}/tiedustelut`}
            >
              Tiedustelut
            </Link>
            <Link
              className={cx('btn btn-default', {
                disabled: activePage === 'feedbacks',
              })}
              to={`/yllapito/palautteet?company=${company.id}`}
            >
              Palautteet
            </Link>
            <Link
              className={cx('btn btn-default', {
                disabled: activePage === 'front-page-entries',
              })}
              to={`/yllapito/yritykset/${company.id}/etusivulla`}
            >
              Etusivulla
            </Link>
          </ButtonGroup>
        </Col>
      </Row>
    </div>
  );
}
