import createClient from '../api/client';
import { UserTwoFactor } from '@typings/UserTwoFactor';

export default {
  fetchSecret: async () =>
    createClient()
      .get<{ readonly provisioningUri: string }>('/two-factor/secret')
      .then(response => response.data),

  getTwoFactor: async (userId: number) =>
    createClient()
      .get<UserTwoFactor>(`/two-factor/${userId}`)
      .then(response => response.data),

  enableTwoFactor: async (passcode: string) =>
    createClient()
      .post<{ readonly success: boolean }>('/two-factor/enable', { passcode })
      .then(response => response.data),
};
