import React from 'react';
import ReactDOM from 'react-dom/client';
import { Global, css } from '@emotion/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { MezzoforteProvider, useTokens } from '@mezzoforte/forge';
import { SiteHeader } from '@app/src/header/SiteHeader';
import { PrismicProvider } from '@prismicio/react';
import richTextComponents from '@app/src/prismic/rich-text-components';
import { linkResolver } from '@app/src/header/prismicio';
import { queryClient } from '@app/src/api/query-client';
import { LoginProvider } from '@components/login/LoginProvider';
// @ts-ignore TODO: TypeScript store
import { store } from './application/store';

const { fonts } = useTokens.huutokaupat;

function GlobalStyles() {
  return (
    <Global
      styles={css`
        :root {
          --font-family: ${fonts.body};
          --heading-font-family: ${fonts.heading};
        }
      `}
    />
  );
}

function render() {
  const headerContainerElement = document.getElementById('header-reactroot');
  if (headerContainerElement) {
    ReactDOM.createRoot(headerContainerElement).render(
      <MezzoforteProvider
        brand="huutokaupat"
        notificationProvider={false}
        toastContainer={false}
        resetCss={false}
        disableGlobalStyle
      >
        <PrismicProvider
          linkResolver={linkResolver}
          richTextComponents={richTextComponents}
        >
          <GlobalStyles />
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <LoginProvider>
                <SiteHeader />
              </LoginProvider>
            </Provider>
          </QueryClientProvider>
        </PrismicProvider>
      </MezzoforteProvider>
    );
  }
}

render();
