import axios from 'axios';

export interface EidentInitResponse {
  readonly redirect: string;
}

export interface EidentBeginResponse {
  readonly success: boolean;
}

export interface EidentProvider {
  readonly providerId: string;
  readonly providerName: string;
  readonly url: string;
  readonly fields: {
    readonly client_id: string;
    readonly response_type: string;
    readonly redirect_uri: string;
    readonly scope: string;
    readonly ui_locales: string;
    readonly state: string;
    readonly nonce: string;
    readonly amr_values: string;
    readonly forcebank: string;
  };
}

export interface EidentProvidersResponse {
  readonly alreadyIdentified: boolean;
  readonly loginRequired: boolean;
  readonly providers: EidentProvider[];
}

export type IdentificationIntent = 'registration' | 'login' | 'update';

export interface EidentOptionsResponse {
  readonly intent: IdentificationIntent;
  readonly returnUrl: string;
}

export function isIdentificationIntent(intent: string): intent is IdentificationIntent {
  return ['registration', 'login', 'update'].includes(intent);
}

export const identificationService = {
  getIdentificationUrl: (intent: IdentificationIntent, returnUrl: string) => {
    const params = new URLSearchParams();
    params.set('intent', intent);
    params.set('returnUrl', returnUrl);

    return `/tunnistaudu?${params.toString()}`;
  },

  init: async (intent: IdentificationIntent, returnUrl?: string) =>
    axios.post<EidentInitResponse>('/eident/init', { intent, returnUrl }).then(response => response.data),

  beginIdentification: async (provider: string) => axios.post<EidentBeginResponse>(`/eident/begin/${provider}`),

  fetchProviders: async () => axios.get<EidentProvidersResponse>('/eident/providers').then(response => response.data),

  getOptions: async () => axios.get<EidentOptionsResponse>('/eident/options').then(response => response.data),
};
