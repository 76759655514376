// @flow

import createClient from '../api/client';

export default {
  getFeedbackByUuid: (uuid: string) =>
    createClient()
      .get(`/net-auctions/feedbacks/${uuid}`)
      .then(response => response.data),

  saveFeedback: (uuid: string, params: Object) =>
    createClient()
      .patch(`/net-auctions/feedbacks/${uuid}`, params)
      .then(response => response.data),
};
