import { Map } from 'immutable';

import feedbackService from '../src/net-auction/feedback-service';

const SAVE_NET_AUCTION_FEEDBACK = 'huutokaupat/net-auction/feedback/SAVE_NET_AUCTION_FEEDBACK';
const SAVE_NET_AUCTION_FEEDBACK_DONE = 'huutokaupat/net-auction/feedback/SAVE_NET_AUCTION_FEEDBACK_DONE';
const SAVE_NET_AUCTION_FEEDBACK_FAILED = 'huutokaupat/net-auction/feedback/SAVE_NET_AUCTION_FEEDBACK_FAILED';

const LOAD_NET_AUCTION_FEEDBACK = 'huutokaupat/net-auction/feedback/LOAD_NET_AUCTION_FEEDBACK';
const LOAD_NET_AUCTION_FEEDBACK_DONE = 'huutokaupat/net-auction/feedback/LOAD_NET_AUCTION_FEEDBACK_DONE';
const LOAD_NET_AUCTION_FEEDBACK_FAILED = 'huutokaupat/net-auction/feedback/LOAD_NET_AUCTION_FEEDBACK_FAILED';

const initialState = new Map({
  loading: false,
  failed: false,
  feedbackTooOld: false,
  submittingExpired: false,
  feedbackNotFound: false,
  messageSent: false,
  message: null,
  rating: null,
  loadingFeedback: false,
  savedRating: false,
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD_NET_AUCTION_FEEDBACK:
      return state.set('loading', true).set('failed', false).set('feedbackNotFound', false);

    case LOAD_NET_AUCTION_FEEDBACK_DONE:
      return state.set('loading', false).set('feedback', action.payload.feedback);

    case SAVE_NET_AUCTION_FEEDBACK:
      return state.set('messageSent', false).set('loading', true).set('failed', false);

    case SAVE_NET_AUCTION_FEEDBACK_DONE:
      return state.set('messageSent', true).set('loading', false);

    case LOAD_NET_AUCTION_FEEDBACK_FAILED:
      return state
        .set('loading', false)
        .set('loadingFailed', true)
        .set('feedbackNotFound', action.payload.response.status === 404);

    case SAVE_NET_AUCTION_FEEDBACK_FAILED:
      return state
        .set('loading', false)
        .set('failed', true)
        .set('feedbackTooOld', !!action.payload.response.data.errors.date)
        .set('submittingExpired', !!action.payload.response.data.errors.submittingExpired);

    default:
      return state;
  }
};

export function loadFeedback(uuid) {
  return dispatch => {
    dispatch({ type: LOAD_NET_AUCTION_FEEDBACK });

    feedbackService
      .getFeedbackByUuid(uuid)
      .then(result => dispatch({ type: LOAD_NET_AUCTION_FEEDBACK_DONE, payload: result }))
      .catch(error => dispatch({ type: LOAD_NET_AUCTION_FEEDBACK_FAILED, payload: error }));
  };
}

export function saveFeedback(uuid, feedback) {
  return dispatch => {
    dispatch({ type: SAVE_NET_AUCTION_FEEDBACK, payload: feedback });

    return feedbackService
      .saveFeedback(uuid, feedback)
      .then(result => dispatch({ type: SAVE_NET_AUCTION_FEEDBACK_DONE, payload: result }))
      .catch(error => dispatch({ type: SAVE_NET_AUCTION_FEEDBACK_FAILED, payload: error }));
  };
}
