import createClient from '../api/client';
import { DiscountTier } from '@app/src/company/company-pricelist-service';

export interface CompanyDiscountTier {
  readonly id: number;
  readonly tier: DiscountTier;
  readonly categories: number[];
  readonly classes: string[];
  readonly activeFrom: string;
  readonly activeTo: string | null;
}

export interface LimitedCompany {
  readonly id: number;
  readonly name: string;
  readonly businessId: string;
  readonly tikonIds: number[];
  readonly isUserEnabled: boolean;
  readonly isUserIdentified: boolean;
  readonly isUserSeller: boolean;
  readonly vatNumber: string;
}

export interface Company extends LimitedCompany {
  readonly createdAt: string;
  readonly slug: string;
  readonly notes: string;
  readonly isOwnPayment: boolean;
  readonly isCustomerFundsProvisionEnabled: boolean;
  readonly isCustomerFundsFrozen: boolean;
  readonly isBankAccountMissing: boolean;
  readonly isBusinessReportEnabled: boolean;
  readonly bankAccount: string;
  readonly reference: string;
  readonly uuid: string;
  readonly status: string;
  readonly feedbacksPublishedAt: string;
  readonly frozenEntriesCount: number;
  readonly monitoringStatus: string;
  readonly monitoringTransferredAt: string;
  readonly dsaAcceptedAt: string | null;
  readonly canSkipDsa: boolean;
  readonly activeDiscountTiers: CompanyDiscountTier[];
}

export interface User {
  readonly userId: number;
  readonly name: string;
  readonly email: string;
  readonly isEnabled: boolean;
  readonly dsaAcceptedAt: string | null;
}

export interface FrontPageEntry {
  readonly id: number;
  readonly title: string;
  readonly frontPageStartedAt: string | null;
  readonly isFrontPageFree: boolean;
}

export type FreshdeskTicketStatus = '2' | '3' | '4' | '5';

export interface FreshdeskTicket {
  readonly created_at: string;
  readonly subject: string;
  readonly id: string;
  readonly status: FreshdeskTicketStatus;
  readonly type: string;
  readonly email: string;
}

export interface CompanyApproval {
  readonly approved: number;
  readonly all: number;
  readonly ratio: number;
}

export interface NetAuctionCounts {
  readonly unpublished: number;
  readonly ongoing: number;
  readonly ended: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention -- TODO
  readonly 'post-trading': number;
  readonly approved: number;
  readonly deliverable: number;
  readonly handover: number;
  readonly archived: number;
  readonly draft: number;
}

export interface CompanySummary {
  readonly company: Company;
  readonly netAuctionCounts: NetAuctionCounts;
  readonly totalNetAuctionCount: number;
  readonly fullUserCount: number;
  readonly companyApproval: CompanyApproval;
  readonly reclamationCount: number;
  readonly monitoringNotificationCount: number;
  readonly partialAnnulmentCount: number;
  readonly fullAnnulmentCount: number;
  readonly type: string;
  readonly internalDisplayName: string;
}

export interface CompanySummaryFrontPage {
  readonly activeCount: number;
  readonly totalCount: number;
  readonly latestAt: string | null;
}

export interface CompanyUsers {
  readonly users: User[];
}

export interface CompanyFrontPageEntries {
  readonly limitedCompany: LimitedCompany;
  readonly frontPageEntries: FrontPageEntry[];
}

export interface FreshdeskTickets {
  readonly freshdeskTickets: FreshdeskTicket[];
}

export const fetchCompanySummaryFrontPage = async (companyId: number) =>
  createClient()
    .get<CompanySummaryFrontPage>(`/companies/${companyId}/summary/front-page`)
    .then(response => response.data);

export default {
  getCompanySummary: async (companyId: number) =>
    createClient()
      .get<CompanySummary>(`/companies/${companyId}/summary`)
      .then(response => response.data),

  getCompanyUsers: async (companyId: number, limit?: number) =>
    createClient()
      .get<CompanyUsers>(`/companies/${companyId}/summary/users`, {
        params: { limit },
      })
      .then(response => response.data),

  getCompanyFrontPageEntries: async (companyId: number) =>
    createClient()
      .get<CompanyFrontPageEntries>(`/companies/${companyId}/front-page-entries`)
      .then(response => response.data),

  updateCompanyNotes: async (companyId: number, notes: string) =>
    createClient()
      .post<{ readonly success: boolean }>(`/companies/${companyId}/update-notes`, { notes })
      .then(response => response.data),

  getFreshdeskTickets: async (companyId: number) =>
    createClient()
      .get<FreshdeskTickets>(`/companies/${companyId}/freshdesk-tickets`)
      .then(response => response.data),
};
