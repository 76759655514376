import dayjs, { Dayjs } from 'dayjs';

export type Vat = 0.0 | 10.0 | 14.0 | 24.0 | 25.5 | 'MARGINAL_VAT';
export type ReportPageVat = Vat | [24.0, 25.5];
export type VatType = 'MARGINAL' | 'REDUCED' | 'MORE_REDUCED' | 'GENERAL';

export interface VatOption {
  readonly value: Vat;
  readonly name: string;
  readonly type: VatType;
  readonly label: string;
  readonly validFrom?: Dayjs;
  readonly validTo?: Dayjs;
  readonly reportPage?: boolean;
}

export interface ReportPageVatOption extends Omit<VatOption, 'value'> {
  readonly value: ReportPageVat;
}

export const vatOptions: VatOption[] = [
  {
    value: 'MARGINAL_VAT',
    name: 'Marginaalivero',
    reportPage: true,
    label: '0 % (marginaaliverollinen)',
    type: 'MARGINAL',
  },
  { value: 0.0, name: 'Veroton', reportPage: false, label: '0 % (veroton)', type: 'MARGINAL' },
  { value: 10.0, name: 'ALV 10%', reportPage: true, label: '10 % (esim. aikakauslehdet)', type: 'MORE_REDUCED' },
  { value: 14.0, name: 'ALV 14%', reportPage: true, label: '14 % (esim. kirjat)', type: 'REDUCED' },
  {
    value: 24.0,
    name: 'ALV 24%',
    validFrom: dayjs('1900-01-01'),
    validTo: dayjs('2024-08-31 23:59:59'),
    reportPage: false,
    label: '24 % (yleinen arvonlisävero)',
    type: 'GENERAL',
  },
  {
    value: 25.5,
    name: 'ALV 25,5%',
    validFrom: dayjs('2024-09-01 00:00:00'),
    reportPage: false,
    label: '25,5 % (yleinen arvonlisävero)',
    type: 'GENERAL',
  },
];

export const reportPageVatOptions: ReportPageVatOption[] = [
  ...vatOptions.filter(vatOption => vatOption.reportPage),
  {
    value: [24.0, 25.5],
    name: '25,5%',
    label: '',
    type: 'GENERAL',
  },
];

export const getVatOptions = (
  date: Dayjs = dayjs(),
  isReportPage: boolean = false,
  isAdmin: boolean = false,
  type?: VatType
): VatOption[] => {
  const options = vatOptions.filter(vatOption => {
    if (!isReportPage) {
      return !vatOption.reportPage;
    }

    return true;
  });

  if (isAdmin) {
    return options;
  }

  return options
    .filter(vatOption => dayjs(vatOption.validFrom).isBefore(date))
    .filter(vatOption => (vatOption.validTo ? dayjs(vatOption.validTo).isAfter(date) : true))
    .filter(vatOption => (type ? vatOption.type === type : false));
};

export const getGeneralVatOption = (date: Dayjs) => {
  return getVatOptions(date, false, false, 'GENERAL')[0];
};

export const getVatOptionsWithoutMarginal = (): VatOption[] =>
  vatOptions.filter(vatOption => vatOption.value !== 'MARGINAL_VAT');
