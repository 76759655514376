import React from 'react';
import { Box, Flex, LinkBox, LinkOverlay, Text } from '@mezzoforte/forge';
import { CompanyIcon } from '@app/src/header/UserGreeting/CompanyIcon';
import { components } from '@typings/schema';

export interface CompanyRowProps {
  readonly company: components['schemas']['Company'];
  readonly onSelect?: (id: number) => void;
}

export const CompanyRow = ({ company, onSelect }: CompanyRowProps) => {
  if (onSelect) {
    return (
      <LinkBox
        display="block"
        py={2}
      >
        <Flex
          flexDir="row"
          gap={2}
        >
          <CompanyIcon company={company} />
          <Text
            fontSize={14}
            fontWeight={500}
            fontFamily="button"
            maxWidth={{ base: 'auto', md: 185 }}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            <LinkOverlay
              href="#"
              onClick={() => onSelect(company.id)}
            >
              {company.internalDisplayName}
            </LinkOverlay>
          </Text>
        </Flex>
      </LinkBox>
    );
  }

  return (
    <Box
      as="span"
      display="block"
      py={2}
    >
      <Flex
        flexDir="row"
        gap={2}
      >
        <CompanyIcon company={company} />
        <Text
          fontSize={14}
          fontWeight={500}
          fontFamily="button"
          maxWidth={{ base: 'auto', md: 185 }}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {company.internalDisplayName}
        </Text>
      </Flex>
    </Box>
  );
};
