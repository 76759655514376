import styled from '@emotion/styled';
import { useTokens, Box, BoxProps } from '@mezzoforte/forge';

const { colors, radii } = useTokens.huutokaupat;

export const ShadowBox = styled(Box)<BoxProps>`
  background-color: ${props => props.backgroundColor ?? colors.background};
  border-radius: ${radii.box};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15), 0px 2px 4px rgba(0, 0, 0, 0.12);
  color: ${colors.text};
`;
